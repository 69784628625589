import { AuthenticationRequestDto } from "./dtos/authentication-request.dto";
import { AuthenticationResponseDto } from "./dtos/authentication-response.dto";
import { BaseService } from "../common/base.service";
import { GameConfiguration } from "./interfaces/game-configuration.interface";
import { GameTypes } from "./enums/game-types.enum";
import { JWTPayload } from "./interfaces/jwt-payload.interface";
import { UserState } from "./interfaces/user-state.interface";
import jwtDecode from "jwt-decode";

export class SessionService extends BaseService {
    private token?: string;

    public constructor(baseUrl?: string) {
        super(baseUrl);
    }

    public async getOrCreateSession(securityToken: string, game: GameTypes): Promise<UserState> {
        // const savedSession = localStorage.getItem(`user-${game}`);
        // if (savedSession) {
        //     const decodedSession = JSON.parse(savedSession) as UserState;
        //     if (
        //         typeof decodedSession === "object" &&
        //         typeof decodedSession.payload !== "undefined" &&
        //         decodedSession.payload.token === securityToken &&
        //         decodedSession.payload.exp * 1e3 > Date.now()
        //     ) {
        //         this.token = decodedSession.token;
        //         return decodedSession;
        //     }
        // }

        const authenticateResponse = await this.authenticate(
            {
                securityToken,
                game,
            }
        );

        const authenticationPayload = jwtDecode<JWTPayload>(authenticateResponse.token);

        if (authenticationPayload.game !== game) {
            throw new Error("Invalid authentication response recieved.")
        }

        const createdSession: UserState = {
            token: authenticateResponse.token,
            payload: authenticationPayload,
            userId: parseInt(authenticationPayload.id.toString(), 10),
            game: authenticationPayload.game as GameTypes,
            config: authenticationPayload.config ? JSON.parse(authenticationPayload.config) as GameConfiguration : null,
        };

        // localStorage.setItem(`user-${game}`, JSON.stringify(createdSession));
        this.token = createdSession.token;

        return createdSession;
    }

    public async authenticate(request: AuthenticationRequestDto): Promise<AuthenticationResponseDto> {
        return await this.requestRest<AuthenticationResponseDto, AuthenticationRequestDto>(
            "/session/authenticate",
            { },
            { },
            "POST",
            request
        );
    }

    protected getToken(): string | undefined {
        return this.token;
    }
}
