import { MinimumMultiplier } from "../../../constants/crash.constant";

export const CrashFlashAnimationDuration = 0.5;
export const CrashExplosionAudioAddress = "/crash-explosion.mp3";
export const CrashLevelAudioAddress = "/crash-level.mp3";
export const CrashThemeAudioAddress = "/crash-theme.ogg";
export const CrashMultipliers = new Map<number, string>(
    [ [ MinimumMultiplier, "#04fbff" ], [ 2, "#3dff00" ], [ 10, "#ffea00" ], [ 30, "#ff8002" ], [ 60, "#ff0400" ] ]
);
export const CrashSmallStars = 50;
export const CrashBigStars = 5;
export const CrashYAxisWidth = 130;
export const CrashXAxisHeight = 100;
export const CrashYOffset = 80;
export const CrashXOffset = 50;
export const CrashMinimumXAxis = 1000;
export const CrashMinimumYAxis = 1.5;
export const CrashFadeAnimationDuration = 300;
export const CrashInitialWidth = 800;
export const CrashInitialHeight = 600;
