/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import "./provably-fair-code.style.scss";
import * as React from "react";
import { atomOneDark } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/cjs/languages/hljs/javascript";
SyntaxHighlighter.registerLanguage("javascript", js);

export class ProvablyFairCode extends React.PureComponent<
{
    children: string;
}> {
    public render(): React.ReactNode {
        return <div className="code">
            <SyntaxHighlighter language="javascript" style={atomOneDark} showLineNumbers={true} useInlineStyles={true}>{this.props.children.trim()}</SyntaxHighlighter>
        </div>;
    }
}
