import { CrashFAQModal } from "../components/crash-modals/modals/crash-faq-modal/crash-faq-modal.component";
import { CrashHowToPlayModal } from "../components/crash-modals/modals/crash-how-to-play-modal/crash-how-to-play-modal.component";
import { CrashInfoModal } from "../components/crash-modals/modals/crash-info-modal/crash-info-modal.component";
import { CrashMessageModal } from "../components/crash-modals/modals/crash-message-modal/crash-message-modal.component";
import { CrashProvablyFairModal } from "../components/crash-modals/modals/crash-provably-fair-modal/crash-provably-fair-modal.component";
import { CrashSettingsModal } from "../components/crash-modals/modals/crash-settings-modal/crash-settings-modal.component";
import React from "react";

interface Modals {
    "how-to-play": CrashHowToPlayModal;
    "faq": CrashFAQModal;
    "provably-fair": CrashProvablyFairModal;
    "information": CrashInfoModal;
    "message": CrashMessageModal;
    "settings": CrashSettingsModal;
}
type ExpandModalProperty<T> = T extends React.PureComponent<infer U> ? U : unknown;

export type ModalTypes = keyof Modals;
export interface Modal<T extends ModalTypes = ModalTypes> {
    type: T;
    props: ExpandModalProperty<Modals[T]>;
}

export interface ModalContextValue {
    currentModal?: Modal;
    openModal: <T extends ModalTypes>(type: T, props: ExpandModalProperty<Modals[T]>) => void;
}

export const ModalContext = React.createContext<ModalContextValue>(
    {
        openModal: () => undefined,
    }
);
