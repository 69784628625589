import { CrashApiService } from "../services/crash/crash-api.service";
import { CrashRenderingConfig } from "../components/crash-renderer/crash-rendering/crash-rendering-config.interface";
import { DefaultMaxAmount, DefaultMinAmount } from "../constants/crash.constant";
import { WsCrashMatchDto } from "../services/crash/dtos/ws-crash-match.dto";
import { WsCrashParticipantDto } from "../services/crash/dtos/ws-crash-participant.dto";
import React from "react";

export interface CrashContextValue {
    balance?: number;
    currency?: string;
    minDeposit: number;
    maxDeposit: number;
    participant?: WsCrashParticipantDto;
    participants: WsCrashParticipantDto[];
    userId?: number;
    crashApiService?: CrashApiService;
    currentMatch?: WsCrashMatchDto;
    lastMatches: WsCrashMatchDto[];
    status: "idle" | "finished" | "waiting" | "started";

    configuration?: CrashRenderingConfig;
    setConfiguration?: (configuration: CrashRenderingConfig) => void;
    isFullscreen: boolean;
    toggleFullscreen?: () => void;
}

export const CrashContext = React.createContext<CrashContextValue>(
    {
        lastMatches: [],
        participants: [],
        maxDeposit: DefaultMaxAmount,
        minDeposit: DefaultMinAmount,
        status: "idle",
        isFullscreen: false,
    }
);
