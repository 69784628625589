import { CrashMultipliers } from "../../crash-rendering.constants";
import { CrashRenderingConfig } from "../../crash-rendering-config.interface";
import { CrashRenderingGamePageState } from "../crash-rendering-game.page";
import { CrashRenderingHelper } from "../../crash-rendering.helper";
import { CrashRenderingLayer } from "../../crash-rendering-layer";
import { MinimumMultiplier } from "../../../../../constants/crash.constant";
import { ObjectPool } from "@tgg/shared";
import Konva from "konva";

const PositionX = 25.5;
const Multipliers = [...CrashMultipliers.keys()].reverse();
export class CrashRenderingGameYAxisLayer extends CrashRenderingLayer<
{
    label: Konva.Text;
}, CrashRenderingGamePageState> {
    private lines: ObjectPool<Konva.Line>;
    private tickers: ObjectPool<{
        label: Konva.Text;
        line: Konva.Line;
    }>;
    private texts: ObjectPool<Konva.Text>;

    public constructor(configuration: CrashRenderingConfig, container: Konva.Group) {
        super(
            configuration,
            container,
            {
                label: new Konva.Text(
                    {
                        fontFamily: "Fira Code",
                        fontSize: 12,
                        text: "",
                        strokeEnabled: false,
                        fillEnabled: true,
                        fill: "#fff",
                        fontStyle: "bold",
                        align: "left",
                        verticalAlign: "middle",
                        height: 0,
                    }
                ),
            }
        );


        this.lines = new ObjectPool(
            () => {
                const line = new Konva.Line(
                    {
                        points: [],
                        strokeWidth: 3,
                        lineCap: "round",
                        strokeEnabled: true,
                        opacity: 0.5,
                        fillEnabled: false,
                    }
                );

                line.transformsEnabled("position");
                this.container.add(line);
                return line;
            },
            (entity, wakeup) => entity.visible(wakeup)
        );

        this.tickers = new ObjectPool(
            () => {
                const line = new Konva.Line(
                    {
                        points: [],
                        strokeWidth: 2,
                        lineCap: "round",
                        fillEnabled: false,
                        strokeEnabled: true,
                        stroke: "#909090",
                    }
                );

                const label = new Konva.Text(
                    {
                        fontFamily: "Fira Code",
                        fontSize: 10,
                        text: "",
                        strokeEnabled: false,
                        fillEnabled: true,
                        fill: "#909090",
                        fontStyle: "bold",
                        align: "left",
                        verticalAlign: "middle",
                        height: 0,
                    }
                );

                line.transformsEnabled("position");
                label.transformsEnabled("position");
                this.container.add(line);
                this.container.add(label);
                return { label, line };
            },
            (entity, wakeup) => {
                entity.label.visible(wakeup);
                entity.line.visible(wakeup);
            }
        );

        this.texts = new ObjectPool(
            () => {
                const label = new Konva.Text(
                    {
                        fontFamily: "Fira Code",
                        fontSize: 10,
                        text: "",
                        strokeEnabled: false,
                        fillEnabled: true,
                        fill: "#909090",
                        fontStyle: "bold",
                        align: "left",
                        verticalAlign: "middle",
                        height: 0,
                    }
                );
                label.transformsEnabled("position");
                this.container.add(label);
                return label;
            },
            (entity, wakeup) => {
                entity.visible(wakeup);
            }
        );
    }

    public destroy(): void {
        for (const line of this.lines.getPool()) {
            line.destroy();
        }
        this.lines.clearPool();

        for (const ticker of this.tickers.getPool()) {
            ticker.label.destroy();
            ticker.line.destroy();
        }
        this.tickers.clearPool();

        super.destroy();
    }

    public tick(state: CrashRenderingGamePageState): void {
        // LABEL
        const yAxisHeight = this.height;
        const currentYPosition = this.getPositionYForMultiplier(
            state.multiplier,
            yAxisHeight,
            state.yIncrement
        );

        if (state.status === "idle" || state.status === "waiting") {
            this.shapes.label.visible(false);
        } else {
            this.shapes.label.setAttrs(
                {
                    visible: true,
                    text: state.multiplier.toFixed(2) + "x",
                    x: PositionX + 20,
                    y: currentYPosition,
                } as Konva.TextConfig
            );
        }

        // AXIS
        this.drawSteps(state);
        super.tick(state);
    }

    protected statusChanged(state: CrashRenderingGamePageState): void {
        if (state.status === "finished") {
            // fire and forget
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.finished();
        } else if (state.status === "started") {
            this.started();
        }
    }

    private started() {
        this.shapes.label.fill("#ffffff");
    }

    private async finished() {
        this.shapes.label.fill("#ff0400");

        if (this.configuration.displayMode !== "rich") {
            return;
        }

        await this.animate(
            {
                node: this.shapes.label,
                duration: 0.4,
                // eslint-disable-next-line @typescript-eslint/unbound-method
                easing: Konva.Easings.StrongEaseIn,
                rotation: 4,
                scaleX: 1.1,
                scaleY: 1.1,
            }
        );

        await this.animate(
            {
                node: this.shapes.label,
                duration: 0.5,
                // eslint-disable-next-line @typescript-eslint/unbound-method
                easing: Konva.Easings.BounceEaseOut,
                rotation: 0,
                scaleX: 1,
                scaleY: 1,
            }
        );
    }

    private drawSteps(state: CrashRenderingGamePageState) {
        const yAxisHeight = this.height;
        const currentYPosition = this.getPositionYForMultiplier(
            state.multiplier,
            yAxisHeight,
            state.yIncrement
        );
        this.lines.transaction(
            (getLine) => {
                this.texts.transaction(
                    (getText) => {
                        let lastY = 0;
                        for (const multiplier of Multipliers) {
                            const y = this.getPositionYForMultiplier(
                                multiplier,
                                yAxisHeight,
                                state.yIncrement
                            );

                            if (y < 0) {
                                continue;
                            }

                            // draw the line and fill it
                            this.drawLine(getLine, lastY, y, multiplier, currentYPosition, yAxisHeight);

                            // draw ticker
                            if (y < yAxisHeight) {
                                this.drawTickerLine(getLine, y, multiplier <= state.multiplier);
                                if (Math.abs(currentYPosition - y) > 15 && (yAxisHeight - y) > 30) {
                                    this.drawTickerLabel(getText, multiplier, y, multiplier <= state.multiplier);
                                }
                            }

                            // draw sub tickers
                            const offsetM = CrashRenderingHelper.stepValues(multiplier);
                            const offsetY = offsetM * state.yIncrement;
                            if (offsetY > 30) {
                                for (
                                    let steps = 0, subtickerY = y, subtickerMultiplier = multiplier;
                                    steps < 100 && subtickerY > lastY + 1;
                                    steps++, subtickerY -= offsetY, subtickerMultiplier += offsetM
                                ) {
                                    if (steps === 0) {
                                        continue;
                                    }

                                    this.drawSubTickerLine(getLine, subtickerMultiplier, subtickerY, currentYPosition <= subtickerY);
                                    if (Math.abs(currentYPosition - subtickerY) > 15) {
                                        this.drawTickerLabel(getText, subtickerMultiplier, subtickerY, currentYPosition <= subtickerY);
                                    }
                                }
                            }

                            if (y >= yAxisHeight) {
                                break;
                            }

                            lastY = y;
                        }
                    }
                );
            }
        );
    }

    private drawLine(getLine: () => Konva.Line, y1: number, y2: number, m: number, currentY: number, maxHeight: number) {
        y1 = y1 > 0 ? Math.min(y1 + 10, maxHeight) : y1;
        y2 = y2 < maxHeight ? Math.max(y2 - 10, 0) : y2;

        if (y2 - y1 <= 0) {
            return;
        }

        let line = getLine();
        line.points(
            [
                PositionX + 5, y1,
                PositionX + 5, y2,
            ]
        );
        line.opacity(0.5);
        line.stroke(CrashRenderingHelper.getMultiplierColor(m));

        if (currentY <= y2) {
            line = getLine();
            line.points(
                [
                    PositionX + 5, Math.max(currentY, y1),
                    PositionX + 5, y2,
                ]
            );
            line.opacity(0.5);
            line.stroke(CrashRenderingHelper.getMultiplierColor(m));
        }
    }

    private drawTickerLine(getLine: () => Konva.Line, y: number, reached?: boolean) {
        const line = getLine();
        line.points(
            [
                PositionX, y,
                PositionX + 10, y,
            ]
        );
        line.opacity(1);
        line.stroke(reached ? "#fff" : "#909090");
    }

    private drawSubTickerLine(getLine: () => Konva.Line, m: number, y: number, reached?: boolean) {
        const line = getLine();
        line.points(
            [
                PositionX + 7.5, y,
                PositionX + 9, y,
            ]
        );
        line.opacity(reached ? 1 : 0.5);
        line.stroke(CrashRenderingHelper.getMultiplierColor(m));
    }

    private drawTickerLabel(getText: () => Konva.Text, m: number, y: number, reached?: boolean) {
        const text = getText();
        text.x(PositionX + 20);
        text.y(y);
        text.text(m.toFixed(m > 100 ? 0 : (m > 10 ? 1 : 2)) + "x");
        text.fill(reached ? "#fff" : "#909090");
    }

    private getMultiplierForPositionY(yPositionRatio: number, axisRange: number) {
        return (
            Math.ceil(
                1000 * (axisRange - yPositionRatio * axisRange + 1)
            ) / 1000
        ) - (1 - MinimumMultiplier);
    }

    private getPositionYForMultiplier(multiplier: number, height: number, yIncrement: number) {
        return height - (multiplier - MinimumMultiplier) * yIncrement;
    }
}
