import "./crash-planet.style.scss";
import { SimpleEventDispatcher } from "strongly-typed-events";
import classNames from "classnames";
import GreenPlanet from "./assets/green-planet.png";
import OrangePlanet from "./assets/orange-planet.png";
import PurplePlanet from "./assets/purple-planet.png";
import React from "react";

export class PlanetItem extends React.PureComponent<{
    type: "orange" | "green" | "purple";
    before?: "question-mark" | "exclamation" | "hash";
    className?: string;
    isAnimate?: boolean;
    isActive?: boolean;
    isJumping?: boolean;
    shrink?: boolean;
}, {
    isForcedDeactive: boolean;
    defaultShrink: boolean;
}> {
    private static removeForcedActivationCallback: SimpleEventDispatcher<undefined> = new SimpleEventDispatcher();
    public constructor(props: PlanetItem["props"]) {
        super(props);
        this.state = {
            isForcedDeactive: false,
            defaultShrink: true,
        };
    }

    public componentDidMount(): void {
        PlanetItem.removeForcedActivationCallback.sub(this.resetForcedSize);
        if (this.props.shrink == null) {
            setTimeout(
                () => {
                    this.setState(
                        {
                            defaultShrink: false,
                        }
                    );
                },
                100
            );
        }
    }

    public componentWillUnmount(): void {
        PlanetItem.removeForcedActivationCallback.unsub(this.resetForcedSize);
    }

    public render(): JSX.Element {
        const beforeStyle = this.props.before ?? "empty";
        return <span
            onClick={this.props.isActive === true ? this.toggleSize : undefined}
            className={
                classNames(
                    "planetItem",
                    beforeStyle,
                    this.props.type,
                    this.props.className,
                    {
                        animate: this.props.isAnimate,
                        active: this.props.isActive === true && !this.state.isForcedDeactive,
                        jumping: this.props.isJumping,
                        clickable: this.props.isActive === true,
                        shrink: this.props.shrink ?? this.state.defaultShrink,
                    }
                )
            }
        >
            <span>
                {this.props.type === "green" && <img src={GreenPlanet} />}
                {this.props.type === "orange" && <img src={OrangePlanet} />}
                {this.props.type === "purple" && <img src={PurplePlanet} />}
            </span>
        </span>;
    }

    private resetForcedSize = () => {
        this.setState(
            {
                isForcedDeactive: false,
            }
        );
    };

    private toggleSize = () => {
        if (this.props.isActive == null) {
            return;
        }

        this.setState(
            {
                isForcedDeactive: !this.state.isForcedDeactive,
            },
            () => {
                if (!this.state.isForcedDeactive) {
                    // void PlanetItem.removeForcedActivationCallback.dispatchAsync(undefined);
                }
            }
        );
    };
}
