import "./provably-fair-hash-string.style.scss";
import * as Icon from "lucide-react";
import * as React from "react";
import { Button } from "../../../../../buttons/button.component";
import classNames from "classnames";

export class ProvablyFairHashString extends React.PureComponent<{
    hash?: string;
}, {
    copied: boolean;
}> {
    private timeout?: ReturnType<typeof setTimeout>;

    public constructor(props: ProvablyFairHashString["props"]) {
        super(props);
        this.state = {
            copied: false,
        };
    }

    public componentWillUnmount(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    public render(): React.ReactNode {
        if (!this.props.hash) {
            return <></>;
        }

        return <span
            className={
                classNames(
                    "hash-string",
                    {
                        copied: this.state.copied,
                    }
                )
            }
        >
            <span>{this.props.hash}</span>
            <Button caption={<Icon.Copy />} onClick={this.onCopy} isTransparent size="small" />
        </span>;
    }

    private onCopy = () => {
        try {
            const dummy = document.createElement("input");
            document.body.appendChild(dummy);
            dummy.value = this.props.hash ?? "";
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            this.setState(
                {
                    copied: true,
                },
                () => {
                    if (this.timeout) {
                        clearTimeout(this.timeout);
                    }
                    this.timeout = setTimeout(
                        () => {
                            this.setState(
                                {
                                    copied: false,
                                }
                            );
                        },
                        2000
                    );
                }
            );
        } catch (error) {
            // ignore
        }
    };
}
