// eslint-disable-next-line max-classes-per-file
import "./crash-faq-modal.style.scss";
import * as Icon from "lucide-react";
import { PlanetItem } from "../../../crash-planet/crash-planet.component";
import classNames from "classnames";
import React from "react";

interface CrashFAQItemProperties {
    title: string;
}

export class CrashFAQModal extends React.PureComponent {
    private static Item = class extends React.PureComponent<CrashFAQItemProperties, {
        isOpen: boolean;
    }> {
        public static displayName = "CrashFAQ.Item";

        public constructor(props: CrashFAQItemProperties) {
            super(props);
            this.state = {
                isOpen: false,
            };
        }

        public render(): JSX.Element {
            return <div
                className={
                    classNames(
                        {
                            expanded: this.state.isOpen,
                        }
                    )
                }>
                <h5 onClick={this.toggleActivate}>
                    {this.props.title} <span>{this.state.isOpen ? <Icon.Minus /> : <Icon.Plus />}</span>
                </h5>
                <article>
                    <p>{this.props.children}</p>
                </article>
            </div>;
        }

        public toggleActivate = () => {
            this.setState(
                {
                    isOpen: !this.state.isOpen,
                }
            );
        };
    };

    public render(): JSX.Element {
        return <>
            <div className="crash-faq-modal">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non pretium odio, bibendum venenatis ante. Suspendisse urna nisi,
                    convallis sed mollis nec, fringilla at quam. Ut augue neque, volutpat scelerisque laoreet ac, commodo congue erat.
                    Curabitur pretium velit non leo tristique, et viverra velit congue. Nullam mattis mollis risus, eget efficitur nisi posuere posuere.
                    Quisque ac imperdiet massa. Proin mollis pretium ipsum, tincidunt pellentesque sem ullamcorper non.
                    Aliquam nec iaculis massa. Proin consequat purus nec mi laoreet elementum. Morbi auctor tortor molestie,
                    pretium nisi id, varius arcu. Phasellus a urna libero. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                </p>
                <article>
                    <div>
                        <CrashFAQModal.Item title="Does this game have a bet limit?">
                            The maximum bet value for roulette and crash games is 3000 coins and 5000 coins for the rest of games.
                        </CrashFAQModal.Item>
                        <CrashFAQModal.Item title="Is this game provably fair?">
                            All our games are provably fair.
                            After each round we produce a hash key which you can enter to find the round and will also show the previous round,
                            so you can be sure our outcomes are 100% legitimate.
                        </CrashFAQModal.Item>
                    </div>
                </article>
            </div>
            <PlanetItem className="faq-planet" type="green" before="question-mark" />
        </>;
    }
}
