export interface TimeSpan {
    totalMilliseconds: number;
    totalSeconds: number;
    totalMinutes: number;
    totalHours: number;
    milliseconds: number;
    seconds: number;
    minutes: number;
    hours: number;
    days: number;
}

export abstract class DateHelper {
    public static getTimeSpan(date1: Date, date2: Date = new Date()): TimeSpan {
        const totalMilliseconds = Math.floor((date1.getTime() - date2.getTime()));
        const totalSeconds = Math.floor(totalMilliseconds / 1000);
        const totalMinutes = Math.floor(totalSeconds / 60);
        const totalHours = Math.floor(totalMinutes / 60);
        const totalDays = Math.floor(totalHours / 24);

        return {
            days: totalDays,
            hours: totalHours % 24,
            minutes: totalMinutes % 60,
            seconds: totalSeconds % 60,
            milliseconds: totalMilliseconds % 1000,
            totalHours,
            totalMinutes,
            totalSeconds,
            totalMilliseconds,
        };
    }

    public static formatDateLong(date?: Date): string | undefined {
        if (!date) {
            return undefined;
        }
        return date.toLocaleString(
            "en-GB",
            {
                hour12: false,
                minute: "2-digit",
                hour: "2-digit",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }
        );
    }

    public static formatDateShort(date?: Date): string | undefined {
        if (!date) {
            return undefined;
        }
        return date.toLocaleString(
            "en-GB",
            {
                hour12: false,
                minute: undefined,
                hour: undefined,
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }
        );
    }

    public static formatTimeShort(date?: Date): string | undefined {
        if (!date) {
            return undefined;
        }
        return date.toLocaleString(
            "en-GB",
            {
                hour12: false,
                minute: "2-digit",
                hour: "2-digit",
                year: undefined,
                month: undefined,
                day: undefined,
            }
        );
    }

    // {s} => number of seconds
    // {ss} => number of seconds 2-digit
    // {S} => total number of seconds
    // {m} => number of minutes
    // {mm} => number of minutes 2-digit
    // {M} => total number of minutes
    // {h} => number of hours
    // {H} => total number of hours
    // {d} => number of days
    // (s) => conditional s, e.g. 10 seconds, 1 second
    //  |  => seperator between the format string and fallback string for unrepresentable values
    public static formatTimespan(timespan: TimeSpan, format: string): string {
        let values: number[] = [];
        let formatted: string[] = [];
        let fallback;

        [format, fallback] = format.split("|");
        const parts = format.split(/[\{|\}]/g);
        const hasZeroFormat = parts.includes("mm") || parts.includes("ss") || parts.includes("hh");
        for (let part of parts) {
            let value: number | undefined;
            switch (part) {
                case "s":
                case "ss":
                    value = timespan.seconds;
                    break;
                case "S":
                    value = timespan.totalSeconds;
                    break;
                case "m":
                case "mm":
                    value = timespan.minutes;
                    break;
                case "M":
                    value = timespan.totalMinutes;
                    break;
                case "h":
                case "hh":
                    value = timespan.hours;
                    break;
                case "H":
                    value = timespan.totalHours;
                    break;
                case "d":
                case "D":
                    value = timespan.days;
                    break;
            }

            if (value != null) {
                values.push(value);
                switch (part) {
                    case "ss":
                    case "mm":
                    case "hh":
                        part = value.toFixed(0).padStart(2, "0");
                        break;
                    default:
                        part = value.toFixed(0);
                        break;
                }
            } else {
                const conditionalIndex = part.trim().lastIndexOf("(s)");
                if (conditionalIndex > 0) {
                    if (values.length !== 0 && Math.abs(values[values.length - 1]) > 1) {
                        part = part.replace("(s)", "s");
                    } else {
                        part = part.replace("(s)", "");
                    }
                }

                if (!hasZeroFormat && (values.length === 0 || values.every((v) => v === 0))) {
                    values = [];
                    formatted = [];
                    continue;
                }
            }

            formatted.push(part);
        }

        if (fallback && (values.length === 0 || values.every((v) => v === 0))) {
            return fallback;
        }

        return formatted.join("");
    }
}
