export abstract class AudioHelper {
    public static loadAudio(audioAddress: string): Promise<HTMLAudioElement> {
        return new Promise<HTMLAudioElement>(
            (resolve, reject) => {
                const audio = new Audio(audioAddress);
                audio.addEventListener("canplaythrough", () => resolve(audio), false);
                audio.onerror = () => reject("Failed to load audio.");
                audio.src = audioAddress;
                audio.load();
            }
        );
    }

    public static playAudio(audio: HTMLAudioElement | undefined | null, delay = 0): void {
        const play = () => {
            if (!audio) {
                return;
            }

            audio.currentTime = 0;
            audio.volume = 1;
            void audio.play();
        };

        if (delay) {
            setTimeout(play, delay);
        } else {
            play();
        }
    }
}
