import { CrashRenderingConfig } from "./crash-rendering-config.interface";
import { CrashRenderingState } from "./crash-rendering-state.interface";
import { KonvaRenderingLayer, KovaRenderingShapes } from "../../../common/utilities/konva-rendering-layer";
import Konva from "konva";

export abstract class CrashRenderingLayer<
    TShapes extends KovaRenderingShapes<CrashRenderingConfig> = any,
    TState extends CrashRenderingState = CrashRenderingState,
    TContainer extends Konva.Group = Konva.Group
> extends KonvaRenderingLayer<CrashRenderingConfig, TShapes, TState, TContainer> {}
