export class ServiceException extends Error {
    public status?: number;
    public response?: string;
    public headers: { [key: string]: unknown };
    protected isApiException = true;

    public constructor(message: string, status?: number, response?: string, headers?: { [key: string]: unknown }) {
        super(message);
        this.status = status;
        this.response = response;
        this.headers = headers ?? {};
    }

    public static isApiException(obj: unknown): obj is ServiceException {
        return !!obj && typeof obj === "object" && (obj as ServiceException).isApiException === true;
    }
}
