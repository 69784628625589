import { BaseService, WsExceptionDto } from "@tgg/shared";
import { SimpleEventDispatcher } from "strongly-typed-events";
import { WsCashoutCrashMatchDto } from "./dtos/ws-cashout-crash-match.dto";
import { WsCrashMatchDto } from "./dtos/ws-crash-match.dto";
import { WsCrashMatchStatusDto } from "./dtos/ws-crash-match-status.dto";
import { WsCrashParticipantDto } from "./dtos/ws-crash-participant.dto";
import { WsJoinCrashMatchDto } from "./dtos/ws-join-crash-match.dto";

export class CrashService extends BaseService {
    public readonly onMatchUpdated = new SimpleEventDispatcher<WsCrashMatchDto>();
    public readonly onMatchesAvailable = new SimpleEventDispatcher<WsCrashMatchDto[]>();
    public readonly onMatchStatusUpdated = new SimpleEventDispatcher<WsCrashMatchStatusDto>();
    public readonly onMatchParticipantsUpdated = new SimpleEventDispatcher<WsCrashParticipantDto[]>();
    public readonly onException = new SimpleEventDispatcher<WsExceptionDto>();
    private token?: string;

    public constructor(baseUrl?: string) {
        super(baseUrl);

        this.registerSocketEvent("exception", this.onException);
        this.registerSocketEvent("CrashMatchUpdated", this.onMatchStatusUpdated);
        this.registerSocketEvent("CrashMatchChanged", this.onMatchUpdated);
        this.registerSocketEvent("CrashMatchHistory", this.onMatchesAvailable);
        this.registerSocketEvent("CrashParticipantsUpdated", this.onMatchParticipantsUpdated);
    }

    public async joinCrashMatch(matchId: number, amount: number, multiplier?: number): Promise<WsCrashParticipantDto> {
        return await this.requestWebSocket<WsCrashParticipantDto, WsJoinCrashMatchDto>(
            "JoinMatch",
            {
                id: matchId,
                amount,
                multiplier,
            }
        );
    }

    public async cashoutCrashMatch(matchId: number): Promise<WsCrashParticipantDto> {
        return await this.requestWebSocket<WsCrashParticipantDto, WsCashoutCrashMatchDto>(
            "CashOutMatch",
            {
                id: matchId,
            }
        );
    }

    public getToken(): string | undefined {
        return this.token;
    }

    public setToken(value: string): void {
        this.token = value;
    }
}
