import { AudioHelper } from "@tgg/shared";
import { CrashFlashAnimationDuration, CrashLevelAudioAddress } from "../../crash-rendering.constants";

import { CrashRenderingConfig } from "../../crash-rendering-config.interface";
import { CrashRenderingLayer } from "../../crash-rendering-layer";
import Konva from "konva";

export class CrashRenderingGameFlashLayer extends CrashRenderingLayer<{
    rectangle: Konva.Rect;
}> {
    private scaleTween?: Konva.Tween;
    private opacityTween?: Konva.Tween;
    private levelAudio: HTMLAudioElement | undefined;

    public constructor(configurtion: CrashRenderingConfig, container: Konva.Group) {
        super(
            configurtion,
            container,
            {
                rectangle: new Konva.Rect(
                    {
                        fillEnabled: true,
                        fillRadialGradientEndRadius: 3,
                        opacity: 0,
                    }
                ),
            }
        );
    }

    public resize(): void {
        const width = this.width;
        const height = this.height;
        this.shapes.rectangle.setAttrs(
            {
                width,
                height,
                fillRadialGradientStartPoint: { x: width * 0.5, y: 0 },
                fillRadialGradientStartRadius: width,
                fillRadialGradientEndPoint: { x: width * 0.5, y: height * 0.5 },
                offsetX: width / 2,
                offsetY: height / 2,
            } as Konva.RectConfig
        );
        super.resize();
    }

    public destroy(): void {
        this.scaleTween?.destroy();
        this.opacityTween?.destroy();
        super.destroy();
    }

    public config(configuration: CrashRenderingConfig): void {
        if (!configuration.isMuted) {
            if (this.levelAudio == null) {
                AudioHelper.loadAudio(CrashLevelAudioAddress).then(
                    (e) => {
                        e.volume = 0.3;
                        e.playbackRate = 1.5;
                        this.levelAudio = e;
                    },
                    () => {
                        // ignore
                    }
                );
            }
        }

        super.config(configuration);
    }

    public flash(color: string, positionX: number, positionY: number, audio: boolean): void {
        this.scaleTween?.destroy();
        this.opacityTween?.destroy();
        this.scaleTween = undefined;
        this.opacityTween = undefined;

        if (this.configuration.displayMode !== "rich") {
            return;
        }

        this.shapes.rectangle.setAttrs(
            {
                x: positionX,
                y: positionY,
                scaleX: 0,
                scaleY: 0,
                opacity: 0,
                fillRadialGradientColorStops: [
                    0.8, "transparent",
                    0.99, color,
                    1, "transparent",
                ],
                scale: { x: 1, y: 1 },
            } as Konva.RectConfig
        );

        this.scaleTween = this.animate(
            {
                node: this.shapes.rectangle,
                scaleX: 200,
                scaleY: 200,
                duration: CrashFlashAnimationDuration,
                onFinish: (tween) => {
                    this.shapes.rectangle.opacity(0);
                    if (this.scaleTween === tween) {
                        this.scaleTween = undefined;
                    }
                },
            },
            0
        );

        this.opacityTween = this.animate(
            {
                node: this.shapes.rectangle,
                opacity: 1,
                duration: CrashFlashAnimationDuration / 2,
                onFinish: (tween) => {
                    if (this.opacityTween === tween) {
                        this.opacityTween = undefined;
                    }
                },
            },
            0
        );

        if (audio && !this.configuration.isMuted) {
            this.levelAudio?.play();
        }
    }
}
