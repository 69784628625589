import { CrashFinishedTimeout, CrashStateDisconnectThreshold, CrashStateLagThreshold, MinimumMultiplier } from "../../constants/crash.constant";
import { CrashRenderingHelper } from "../crash-renderer/crash-rendering/crash-rendering.helper";
import { CrashRenderingState } from "../crash-renderer/crash-rendering/crash-rendering-state.interface";
import { SimpleEventDispatcher } from "strongly-typed-events";
import { WsCrashMatchDto } from "../../services/crash/dtos/ws-crash-match.dto";
import { WsCrashParticipantDto } from "../../services/crash/dtos/ws-crash-participant.dto";
export interface CrashMatchState {
    matchId?: number;
    multiplier?: number;
    elapsed?: number;
    crashed?: number;
    started?: boolean;
    updated?: Date;
}

export class CrashGameEngine {
    public readonly onMatchStatusChanged = new SimpleEventDispatcher<CrashRenderingState>();
    public readonly onLineColorChanged = new SimpleEventDispatcher<string | undefined>();
    private delta?: number;
    private lastState: CrashRenderingState;
    private lastMatch?: WsCrashMatchDto;
    private lastLineColor?: string;
    private currentMatch?: WsCrashMatchDto;
    private currentParticipant?: WsCrashParticipantDto;
    private lastSignalledMatchId?: number;
    private lastSignalledStatus?: CrashRenderingState["status"];
    private getCurrentStatus: () => CrashMatchState;

    public constructor(getCurrentStatus: () => CrashMatchState) {
        this.getCurrentStatus = getCurrentStatus;
        this.lastState = {
            elapsed: 0,
            multiplier: MinimumMultiplier,
            isLagging: false,
            speed: 0.00006,
            status: "idle",
        };
    }

    public getLastState(): CrashRenderingState {
        return { ...this.lastState };
    }

    public updateState(currentMatch?: WsCrashMatchDto, lastMatch?: WsCrashMatchDto, participant?: WsCrashParticipantDto): CrashRenderingState {
        if (currentMatch) {
            if (!this.currentMatch) {
                this.currentMatch = currentMatch;
                this.currentParticipant = participant;
            } else if (this.currentMatch.id !== currentMatch.id) {
                this.lastMatch = lastMatch ?? this.currentMatch;
                this.currentMatch = currentMatch;
                this.currentParticipant = participant;
            }
        } else if (!this.currentMatch) {
            return this.lastState;
        }

        if (participant) {
            this.currentParticipant = participant;
        }

        let now = new Date(Date.now() + (this.delta ?? 0));
        let elapsed: number | undefined;
        let multiplier: number | undefined;

        // information about the last match is available and it is ended recently
        let matchCrashElapsed: number | undefined;

        if (this.lastMatch?.end && (now.getTime() - new Date(this.lastMatch.end).getTime()) < CrashFinishedTimeout) {
            matchCrashElapsed = now.getTime() - new Date(this.lastMatch.end).getTime();
            // fixed match duration and multiplier for pause screen
            elapsed = new Date(this.lastMatch.end).getTime() - new Date(this.lastMatch.start).getTime();
            multiplier = this.lastMatch.multiplier ?? CrashRenderingHelper.getMultiplierForElapsedTime(elapsed, this.lastMatch.speed);
        }

        // current match ended
        if (this.currentMatch.end != null) {
            matchCrashElapsed = now.getTime() - new Date(this.currentMatch.end).getTime();
        }

        // waiting for the new match to start
        let matchElapsed: number | undefined;
        let matchCountdown: number | undefined;
        const startTime = new Date(this.currentMatch.start);
        if (startTime > now) {
            matchCountdown = startTime.getTime() - now.getTime();
        } else {
            // not ended yet but already started
            matchElapsed = now.getTime() - startTime.getTime();
        }

        // get state information
        now = new Date();
        const state = this.getCurrentStatus();
        let stateCrashElapsed: number | undefined;
        let stateElapsed: number | undefined;
        let stateCountdown: number | undefined;
        if (state.matchId && state.matchId === this.currentMatch.id) {
            if (state.crashed != null) {
                // crashed!
                stateCrashElapsed = state.crashed + (now.getTime() - state.updated!.getTime());
                elapsed = state.elapsed;
                multiplier = state.multiplier ?? (elapsed ? CrashRenderingHelper.getMultiplierForElapsedTime(elapsed, this.currentMatch.speed) : undefined);
            } else if (state.elapsed != null && state.elapsed < 0) {
                // waiting for new game
                stateCountdown = -1 * (state.elapsed + (now.getTime() - state.updated!.getTime()));
            } else if (state.elapsed != null) {
                // in the middle of a game
                stateElapsed = state.elapsed + (now.getTime() - state.updated!.getTime());
            }
        }

        // calculate delta
        let delta: number | undefined;
        if (matchCrashElapsed != null && stateCrashElapsed != null) {
            delta = stateCrashElapsed - matchCrashElapsed;
        }

        if (matchCountdown != null && stateCountdown != null) {
            delta = matchCountdown - stateCountdown;
        }

        if (matchElapsed != null && stateElapsed != null) {
            delta = stateElapsed - matchElapsed;
        }

        // move delta forward
        if (delta) {
            this.delta = this.delta != null ? this.delta + delta : delta;
        }

        // fill missing info
        matchCrashElapsed = matchCrashElapsed ?? stateCrashElapsed;
        matchCountdown = matchCountdown ?? stateCountdown;
        matchElapsed = matchElapsed ?? stateElapsed;

        // if crash is too much in the past, remove it
        if (matchCrashElapsed != null && matchCrashElapsed >= CrashFinishedTimeout) {
            matchCrashElapsed = undefined;
        }

        if (matchCountdown != null && matchCountdown < 0) {
            matchCountdown = undefined;
        }

        if (matchElapsed != null && matchElapsed < 0) {
            matchElapsed = undefined;
        }

        // find corresponding status for the available variables
        let status: CrashRenderingState["status"] | undefined;
        if (matchCrashElapsed != null) {
            status = "finished";
        } else if (matchCountdown != null) {
            status = "waiting";
        } else if (matchElapsed != null) {
            status = "started";
        } else {
            status = "idle";
        }

        const lastUpdated = state.updated ? (now.getTime() - state.updated.getTime()) : undefined;

        // fill missing duration and multiplier
        elapsed = elapsed ?? matchElapsed ?? 0;
        multiplier = multiplier ?? (elapsed ? CrashRenderingHelper.getMultiplierForElapsedTime(elapsed, this.currentMatch.speed) : MinimumMultiplier);
        this.lastState = {
            match: this.currentMatch,
            participant: this.currentParticipant,
            elapsed,
            multiplier,
            countdown: matchCountdown,
            crashElapsed: matchCrashElapsed,
            isLagging: !!lastUpdated && lastUpdated >= CrashStateLagThreshold,
            speed: this.currentMatch.speed,
            delta: this.delta ?? 0,
            status,
        };

        if (lastUpdated && !!lastUpdated && lastUpdated >= CrashStateDisconnectThreshold) {
            this.currentMatch = undefined;
            this.lastState = {
                elapsed: 0,
                multiplier: MinimumMultiplier,
                isLagging: false,
                speed: 0.00006,
                status: "idle",
            };
        }

        const lineColor = this.lastState.status === "started" ?
            CrashRenderingHelper.getMultiplierColor(this.lastState.multiplier) :
            undefined;
        if (lineColor !== this.lastLineColor) {
            this.onLineColorChanged.dispatch(lineColor);
            this.lastLineColor = lineColor;
        }

        if (
            this.currentMatch?.id !== this.lastSignalledMatchId ||
            this.lastState.status !== this.lastSignalledStatus
        ) {
            this.lastSignalledMatchId = this.currentMatch?.id;
            this.lastSignalledStatus = this.lastState.status;
            this.onMatchStatusChanged.dispatch(this.lastState);
        }

        return this.lastState;
    }
}
