import { CrashRenderingConfig } from "../../crash-rendering-config.interface";
import { CrashRenderingGamePageState } from "../crash-rendering-game.page";
import { CrashRenderingHelper } from "../../crash-rendering.helper";
import { CrashRenderingLayer } from "../../crash-rendering-layer";
import { ObjectPool } from "@tgg/shared";
import { TextConfig } from "konva/types/shapes/Text";
import Konva from "konva";

export class CrashRenderingGameXAxisLayer extends CrashRenderingLayer<any, CrashRenderingGamePageState> {
    private lines: ObjectPool<Konva.Line>;
    private tickers: ObjectPool<{
        label: Konva.Text;
        line: Konva.Line;
    }>;

    public constructor(configuration: CrashRenderingConfig, container: Konva.Group) {
        super(
            configuration,
            container
        );

        this.lines = new ObjectPool(
            () => {
                const line = new Konva.Line(
                    {
                        points: [],
                        strokeWidth: 3,
                        lineCap: "round",
                        strokeEnabled: true,
                        stroke: "#909090",
                        fillEnabled: false,
                    }
                ).transformsEnabled("position");

                this.container.add(line);
                return line;
            },
            (entity, wakeup) => entity.visible(wakeup)
        );

        this.tickers = new ObjectPool(
            () => {
                const line = new Konva.Line(
                    {
                        points: [],
                        strokeWidth: 2,
                        lineCap: "round",
                        fillEnabled: false,
                        strokeEnabled: true,
                        stroke: "#909090",
                    }
                ).transformsEnabled("position");

                const label = new Konva.Text(
                    {
                        fontFamily: "Fira Code",
                        fontSize: 10,
                        text: "",
                        strokeEnabled: false,
                        fillEnabled: true,
                        fill: "#909090",
                        fontStyle: "bold",
                    }
                ).transformsEnabled("position");

                this.container.add(line, label);
                return { label, line };
            },
            (entity, wakeup) => {
                entity.label.visible(wakeup);
                entity.line.visible(wakeup);
            }
        );
    }


    public destroy(): void {
        for (const line of this.lines.getPool()) {
            line.destroy();
        }
        this.lines.clearPool();

        for (const ticker of this.tickers.getPool()) {
            ticker.label.destroy();
            ticker.line.destroy();
        }
        this.tickers.clearPool();

        super.destroy();
    }

    public tick(state: CrashRenderingGamePageState): void {
        // draw x axis
        const width = this.width;
        const stepOffsetX = CrashRenderingHelper.stepValues(state.xAxisRange, 5, 2);
        const stepScaleX = width / (state.xAxisRange / stepOffsetX);
        this.lines.transaction(
            (getLine) => {
                this.tickers.transaction(
                    (getTicker) => {
                        let lastPositionX = -10;
                        let lastLabelValue = -1;

                        for (
                            let step = 1, offset = stepOffsetX;
                            offset < (state.xAxisRange + stepOffsetX * 2) && step <= 100;
                            offset += stepOffsetX, step++
                        ) {
                            const seconds = Math.floor(offset / 1000);

                            const positionX = 0 === step ? 4 : step * stepScaleX;
                            const positionY = 10 + 20;
                            if (positionX < 0 || (lastPositionX + 10) > width) {
                                continue;
                            }

                            const line = getLine();
                            line.points(
                                [
                                    Math.max(lastPositionX + 10, 0), positionY - 5,
                                    Math.min(Math.max(positionX - 10, 0), width), positionY - 5,
                                ]
                            );

                            if (lastLabelValue === seconds) {
                                continue;
                            }

                            lastLabelValue = seconds;
                            lastPositionX = positionX;

                            if (positionX > width) {
                                continue;
                            }

                            const textConfig: TextConfig = {
                                text: seconds.toFixed(0) + "s",
                                fontStyle: "bold",
                            };

                            const ticker = getTicker();

                            ticker.label.setText(textConfig.text);
                            ticker.label.x(positionX - new Konva.Text(textConfig).size().width / 2);
                            ticker.label.y(positionY + 10);

                            ticker.line.points(
                                [
                                    positionX, positionY,
                                    positionX, positionY - 10,
                                ]
                            );
                        }
                    }
                );
            }
        );
    }
}
