import "./crash-renderer.style.scss";
import { CrashFadeAnimationDuration, CrashInitialHeight, CrashInitialWidth } from "./crash-rendering/crash-rendering.constants";
import { CrashRendererProperties } from "./crash-renderer.properties";
import { CrashRenderingBackground } from "./crash-rendering/pages/crash-rendering-background";
import { CrashRenderingCountdownPage } from "./crash-rendering/pages/crash-rendering-countdown.page";
import { CrashRenderingGamePage } from "./crash-rendering/pages/crash-rendering-game.page";
import { CrashRenderingIdlePage } from "./crash-rendering/pages/crash-rendering-idle.page";
import { CrashRenderingLayer } from "./crash-rendering/crash-rendering-layer";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { KonvaRenderingPageManager } from "../../common/utilities/konva-rendering-page-manager";

import { AccountingHelper } from "@tgg/shared";
import { CrashContext } from "../../contexts/crash.context";
import { CrashFinishedTimeout } from "../../constants/crash.constant";
import { WsCrashParticipantDto } from "../../services/crash/dtos/ws-crash-participant.dto";
import classnames from "classnames";
import Konva from "konva";
import Logo from "./assets/crash-logo.png";
import React from "react";

enum CrashRendererPage {
    Game = "Game",
    Countdown = "Countdown",
    Idle = "Idle"
}

export class CrashRenderer extends React.PureComponent<CrashRendererProperties, {
    lastSize: { width: number; height: number };
    lineColor?: string;
}> {
    private containerReference = React.createRef<HTMLDivElement>();
    private animationFrameId?: number;
    private isComponentMounted = false;
    private stage?: Konva.Stage;
    private pageManager?: KonvaRenderingPageManager<CrashRendererPage, CrashRenderingLayer>;
    private background?: CrashRenderingBackground;

    public constructor(props: CrashRendererProperties) {
        super(props);
        this.state = {
            lastSize: { width: CrashInitialWidth, height: CrashInitialHeight },
        };
    }

    public componentDidMount(): void {
        this.isComponentMounted = true;
        if (!this.containerReference.current) {
            return;
        }

        this.props.engine.onMatchStatusChanged.subscribe(this.onStateChanged);
        this.props.engine.onLineColorChanged.subscribe(this.onLineColorChanged);
        this.initiateStage(this.containerReference.current);
        this.animationFrameId = requestAnimationFrame(() => this.drawStage());
    }

    public componentWillUnmount(): void {
        this.isComponentMounted = false;
        if (this.animationFrameId != null) {
            cancelAnimationFrame(this.animationFrameId);
            this.animationFrameId = undefined;
        }

        this.props.engine.onMatchStatusChanged.unsub(this.onStateChanged);
        this.props.engine.onLineColorChanged.unsub(this.onLineColorChanged);
        this.destroyStage();
    }

    public componentDidUpdate(lastProps: CrashRendererProperties): void {
        if (lastProps.configuration !== this.props.configuration) {
            this.pageManager?.config(this.props.configuration);
            this.background?.config(this.props.configuration);
        }
    }

    public render(): JSX.Element {
        return <CrashContext.Consumer>
            {
                (crashContext) => {
                    const transactionKey = this.getInfoTransitionKey(crashContext.participant);
                    return <div
                        className={
                            classnames(
                                "crashRenderer",
                                this.props.className,
                                {
                                    scale75: this.state.lastSize.width < 600,
                                    scale50: this.state.lastSize.width < 480,
                                    finished: crashContext.status === "finished",
                                    crashed: crashContext.status === "finished" && this.props.configuration.displayMode === "rich",
                                }
                            )
                        }
                        style={
                            this.state.lineColor ? {
                                ["--line-color" as any]: this.state.lineColor,
                            } : {}
                        }
                    >
                        <SwitchTransition mode={"out-in"}>
                            <CSSTransition
                                key={transactionKey}
                                classNames="fade"
                                timeout={100}
                            >
                                <>
                                    {
                                        transactionKey === "won" &&
                                        <h2 className="won">
                                            You have won
                                            <span>
                                                {crashContext.currency} {
                                                    AccountingHelper.formatMoney(
                                                        (crashContext.participant?.deposit ?? 0) * (crashContext.participant?.multiplier ?? 0)
                                                    )
                                                }
                                            </span>
                                            at {(crashContext.participant?.multiplier ?? 0).toFixed(2)}x
                                        </h2>
                                    }
                                    {
                                        transactionKey === "info" &&
                                        <h2>
                                            You have bet
                                            <span>{crashContext.currency} {AccountingHelper.formatMoney((crashContext.participant?.deposit ?? 0))}.</span>
                                            Cashout any time
                                        </h2>
                                    }
                                </>
                            </CSSTransition>
                        </SwitchTransition>
                        <main ref={this.containerReference} />
                        <span className="wallet">
                            {
                                crashContext.balance != null ?
                                    <>Wallet {crashContext.currency} {AccountingHelper.formatBalance(crashContext.balance)}</>
                                    : "Login"
                            }
                        </span>
                        <img src={Logo} />
                    </div>;
                }
            }
        </CrashContext.Consumer>;
    }

    private getInfoTransitionKey(participant: WsCrashParticipantDto | undefined) {
        if (!participant) {
            return "none";
        }

        if (!!participant.cashOut && !!participant.multiplier) {
            return "won";
        }

        if (this.props.engine?.getLastState().status === "started") {
            return "info";
        }

        return "none";
    }

    private onLineColorChanged = (lineColor?: string) => {
        this.setState(
            {
                lineColor,
            }
        );
    };

    private onStateChanged = () => {
        this.forceUpdate();
    };

    private drawStage() {
        if (!this.isComponentMounted || !this.pageManager || !this.stage || !this.props.engine) {
            return;
        }

        const state = this.props.engine.updateState();

        if (state.status === "idle" || state.delta == null) {
            this.pageManager.fadeToPage(CrashRendererPage.Idle);
        } else if (state.status === "waiting" && state.countdown != null && state.countdown > CrashFadeAnimationDuration) {
            this.pageManager.fadeToPage(CrashRendererPage.Countdown);
        } else if (state.status === "finished" && state.crashElapsed != null && (CrashFinishedTimeout - state.crashElapsed) < CrashFadeAnimationDuration) {
            this.pageManager.fadeToPage(CrashRendererPage.Countdown);
        } else {
            this.pageManager.fadeToPage(CrashRendererPage.Game);
        }

        if (this.containerReference.current) {
            let width = this.containerReference.current.clientWidth;
            let height = this.containerReference.current.clientHeight;
            if (
                this.state.lastSize.width !== width ||
                this.state.lastSize.height !== height
            ) {
                this.setState(
                    {
                        lastSize: { width, height },
                    }
                );
                if (width < 480) {
                    this.stage.pixelSize(1);
                    this.stage.scale({ x: 0.5, y: 0.5 });
                    width /= 0.5;
                    height /= 0.5;
                } else if (width < 600) {
                    this.stage.pixelSize(2);
                    this.stage.scale({ x: 0.75, y: 0.75 });
                    width /= 0.75;
                    height /= 0.75;
                } else {
                    this.stage.pixelSize(2);
                    this.stage.scale({ x: 1, y: 1 });
                }
                this.stage.width(width);
                this.stage.height(height);
                this.pageManager.resize();
                this.background?.resize();
            }
        }

        // render
        this.background?.tick(state);
        this.pageManager.tick(state);
        this.animationFrameId = requestAnimationFrame(() => this.drawStage());
        // setTimeout(() => this.drawStage(), 50);
    }

    private initiateStage(container: HTMLDivElement) {
        this.stage = new Konva.Stage(
            {
                container,
                width: CrashInitialWidth,
                height: CrashInitialHeight,
            }
        );
        const backgroundLayer = new Konva.Layer();
        const pageManagerLayer = new Konva.Layer();
        this.stage.add(backgroundLayer);
        this.stage.add(pageManagerLayer);

        this.background = new CrashRenderingBackground(
            this.props.configuration,
            backgroundLayer
        );

        this.pageManager = new KonvaRenderingPageManager(
            this.props.configuration,
            pageManagerLayer,
            CrashFadeAnimationDuration
        );

        this.pageManager.addPage(
            CrashRendererPage.Game,
            (g) => new CrashRenderingGamePage(this.props.configuration, g)
        );

        this.pageManager.addPage(
            CrashRendererPage.Countdown,
            (g) => new CrashRenderingCountdownPage(this.props.configuration, g)
        );

        this.pageManager.addPage(
            CrashRendererPage.Idle,
            (g) => new CrashRenderingIdlePage(this.props.configuration, g)
        );
        this.pageManager.fadeToPage(CrashRendererPage.Idle);
    }

    private destroyStage() {
        this.background?.destroy();
        this.pageManager?.destroy();
        this.stage?.destroy();
        this.pageManager = undefined;
        this.stage = undefined;
    }
}
