import "./crash-history.style.scss";
import { CrashContext } from "../../contexts/crash.context";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ModalContext } from "../../contexts/modal.context";
import { WsCrashMatchDto } from "../../services/crash/dtos/ws-crash-match.dto";
import classnames from "classnames";
import React from "react";

export class CrashHistory extends React.PureComponent {
    public render(): JSX.Element {
        return <CrashContext.Consumer>
            {
                (context) => <section className="crashHistory">
                    <TransitionGroup appear enter >
                        {context.lastMatches?.map((m) => this.renderMatch(m))}
                    </TransitionGroup>
                </section>
            }
        </CrashContext.Consumer>;
    }

    private renderMatch(match: WsCrashMatchDto) {
        return <ModalContext.Consumer key={`match-${match.id}`}>
            {
                (context) => <CSSTransition
                    classNames="fade"
                    timeout={300}
                >
                    <span
                        onClick={() => context.openModal("information", { matchId: match.id })}
                        className={
                            classnames(
                                {
                                    low: match.multiplier != null && match.multiplier < 2,
                                    high: match.multiplier != null && match.multiplier >= 2,
                                }
                            )
                        }
                    >
                        {match.multiplier ? (match.multiplier.toFixed(2) + "x") : undefined}
                    </span>
                </CSSTransition>
            }
        </ModalContext.Consumer>;
    }
}
