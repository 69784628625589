import "./crash-footer.style.scss";
import { CrashContext } from "../../contexts/crash.context";
import { ModalContext } from "../../contexts/modal.context";
import React from "react";

export class CrashFooter extends React.PureComponent {
    public render(): JSX.Element {
        return <ModalContext.Consumer>
            {
                (modalContext) => <CrashContext.Consumer>
                    {
                        (crashContext) => <footer>
                            <span>© 2021-{new Date().getFullYear()} Nocturne Studios. All rights reserved.</span>
                            <ul>
                                <li>
                                    <button
                                        onClick={() => modalContext.openModal("how-to-play", {})}
                                    >HOW TO PLAY</button>
                                </li>
                                {/* <li>
                                    <button
                                        onClick={() => modalContext.openModal("faq", {})}
                                    >FAQ</button>
                                </li> */}
                                <li>
                                    <button
                                        onClick={
                                            crashContext.crashApiService ?
                                                (() => modalContext.openModal("settings", { })) :
                                                undefined
                                        }
                                    >SETTINGS</button>
                                </li>
                                <li>
                                    <button
                                        onClick={
                                            crashContext.crashApiService ?
                                                (() => modalContext.openModal("provably-fair", { crashApiService: crashContext.crashApiService! })) :
                                                undefined
                                        }
                                    >PROVABLY FAIR</button>
                                </li>
                            </ul>
                        </footer>
                    }
                </CrashContext.Consumer>
            }
        </ModalContext.Consumer>;
    }
}
