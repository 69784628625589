import "./textinput.style.scss";
import * as Icon from "lucide-react";
import { Button } from "../buttons/button.component";
import { TextFieldProperties } from "./textinput.properties";
import classnames from "classnames";
import React, { ChangeEvent, FocusEvent, KeyboardEvent } from "react";

export class TextInput extends React.PureComponent<TextFieldProperties, {
    value?: string;
    copping: boolean;
}> {
    private lastValue?: string;
    private inputReference = React.createRef<HTMLInputElement>();

    public constructor(props: TextFieldProperties) {
        super(props);
        this.state = {
            value: props.value,
            copping: false,
        };

        this.lastValue = props.value;
    }

    public componentDidUpdate(prevProperties: TextFieldProperties): void {
        if (prevProperties.value !== this.props.value) {
            this.setState(
                {
                    value: this.props.value,
                }
            );

            this.lastValue = this.props.value;
        }
    }

    public focus(): void {
        setTimeout(
            () => {
                this.inputReference.current?.focus();
                this.inputReference.current?.setSelectionRange(
                    this.inputReference.current.value?.length ?? 0,
                    this.inputReference.current.value?.length ?? 0
                );
            },
            100
        );
    }

    public copy(): void {
        if (!this.inputReference.current) {
            return;
        }

        this.inputReference.current.focus();
        this.inputReference.current.select();
        this.inputReference.current.setSelectionRange(
            0,
            this.inputReference.current.value.length
        );
        document.execCommand("copy");

        if (window.getSelection) {
            window.getSelection()?.removeAllRanges();
        }
    }

    public render(): JSX.Element {
        const { placeholder, isDisabled, isReadOnly, type, min, max, className } = this.props;
        const input = <div
            className={
                classnames(
                    className,
                    "textfield",
                    {
                        disabled: isDisabled || isReadOnly,
                        readOnly: isReadOnly,
                    }
                )
            }
        >
            {this.props.prefix && !!this.state.value && <span>{this.props.prefix}</span>}
            <input
                className={
                    classnames(
                        {
                            disabled: isDisabled || isReadOnly,
                            prefix: !!this.props.prefix && !!this.state.value,
                            clearable: !!this.props.isClearable && !!this.state.value,
                        }
                    )
                }
                ref={this.inputReference}
                disabled={isDisabled || isReadOnly}
                placeholder={placeholder}
                onChange={this.onTextChange}
                onBlur={this.onTextChanged}
                onKeyDown={this.onKeyDown}
                value={this.state.value ?? ""}
                type={type ?? "text"}
                min={type === "number" ? min : undefined}
                max={type === "number" ? max : undefined}
                minLength={type !== "number" ? min : undefined}
                maxLength={type !== "number" ? max : undefined}
            />
            {/* TODO change clear span to icon */}
            {
                this.props.isClearable && this.state.value && <Button
                    onClick={
                        () => {
                            if (this.props.onChanged && this.props.onChanged("")) {
                                this.setState({ value: "" });
                                this.inputReference.current?.focus();
                            }
                        }
                    }
                    caption={<Icon.XCircle />}
                />
            }
        </div>;

        return input;
    }

    private onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (this.props.isDisabled || this.props.isReadOnly) {
            return;
        }

        if (this.props.onChange) {
            if (this.props.onChange(e.target.value) === false) {
                return;
            }
        }

        this.setState({ value: e.target.value });
    };

    private onTextChanged = (e: FocusEvent<HTMLInputElement>) => {
        if (this.props.isDisabled || this.props.isReadOnly) {
            return;
        }

        let value: string | undefined = e.target.value;

        if (this.props.onChanged) {
            if (this.props.onChanged(value) === false) {
                value = this.lastValue;
            }
        }

        if (this.props.shouldClearOnBlur) {
            value = undefined;
        }

        this.lastValue = value;
        this.setState({ value });
    };

    private onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (this.props.isDisabled || this.props.isReadOnly || e.key !== "Enter") {
            return;
        }

        let value: string | undefined = (e.target as HTMLInputElement).value;

        if (this.props.onEnter) {
            if (this.props.onEnter(value) === false) {
                value = this.lastValue;
            }
        }

        if (this.props.shouldClearOnEnter) {
            value = undefined;
        }

        this.lastValue = value;
        this.setState({ value });
    };
}
