import { CrashMultipliers } from "./crash-rendering.constants";
import { MinimumMultiplier } from "../../../constants/crash.constant";

export class CrashRenderingHelper {
    public static getMultiplierColor(multiplier: number): string {
        const keys = [...CrashMultipliers.keys()].sort((a, b) => a - b);
        let lastColor: string | undefined;
        for (const key of keys) {
            if (multiplier < key && lastColor != null) {
                return lastColor;
            }
            lastColor = CrashMultipliers.get(key);
        }

        return lastColor ?? "#FFF";
    }

    public static getMultiplierForElapsedTime(elapsedTime: number, speed: number): number {
        if (elapsedTime <= 0) {
            return MinimumMultiplier;
        }

        const value = ~~(10000 * Math.pow(Math.E, speed * elapsedTime)) / 10000;
        if (isFinite(value)) {
            return Math.max(value, 1) - (1 - MinimumMultiplier);
        }

        throw new Error("Invalid operation exception.");
    }

    public static getElapsedTimeForMultiplier(multiplier: number, speed: number): number {
        multiplier += (1 - MinimumMultiplier);
        if (multiplier <= 1) {
            return 0;
        }

        return (
            100 *
            Math.ceil(
                Math.log(multiplier) / Math.log(Math.E) / speed / 100
            )
        );
    }

    public static stepValues(multiplier: number, e = 5, n = 2): number {
        for (let i = 0.4, r = 0.1; ;) {
            if (multiplier < i) {
                return r;
            }

            r *= n;
            i *= e;

            if (multiplier < i) {
                return r;
            }

            r *= e;
            i *= n;
        }
    }
}
