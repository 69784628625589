import { BaseService, ResultSet } from "@tgg/shared";
import { CrashMatchDto } from "./dtos/crash-match.dto";
import { MatchParametersDto } from "./dtos/crash-match-parameters.dto";


export class CrashApiService extends BaseService {
    private token?: string;

    public constructor(baseUrl?: string) {
        super(baseUrl);
    }

    public async getMatches(parameters: MatchParametersDto): Promise<ResultSet<CrashMatchDto>> {
        return await this.requestRest<ResultSet<CrashMatchDto>, unknown>(
            "/",
            { },
            parameters,
            "GET",
            {}
        );
    }

    public setToken(value: string): void {
        this.token = value;
    }

    protected getToken(): string | undefined {
        return this.token;
    }
}
