import { CrashRenderingConfig } from "../../crash-rendering-config.interface";
import { CrashRenderingLayer } from "../../crash-rendering-layer";
import { CrashRenderingState } from "../../crash-rendering-state.interface";
import Konva from "konva";

export class CrashRenderingGameMultiplierLayer extends CrashRenderingLayer<{
    multiplier: Konva.Text;
    subtitle: Konva.Text;
}> {
    public constructor(configuration: CrashRenderingConfig, container: Konva.Group) {
        super(
            configuration,
            container,
            {
                multiplier: new Konva.Text(
                    {
                        x: 0,
                        offsetY: 40,
                        fillEnabled: true,
                        align: "center",
                        verticalAlign: "middle",
                        fontFamily: "Fira Code",
                        fontSize: 80,
                        fill: "#ffffff",
                    }
                ),
                subtitle: new Konva.Text(
                    {
                        x: 0,
                        offsetY: 6,
                        text: "Current Payout",
                        fillEnabled: true,
                        align: "center",
                        verticalAlign: "middle",
                        fontFamily: "Montserrat",
                        fontSize: 12,
                        fill: "#909090",
                    }
                ),
            }
        );
    }

    public resize(): void {
        const width = this.width;
        const height = this.height;

        this.shapes.multiplier.setAttrs(
            {
                y: (height * 0.1) + 40,
                offsetX: width * 0.2,
                width,
            } as Konva.TextConfig
        );

        this.shapes.subtitle.setAttrs(
            {
                y: (height * 0.1) + 90,
                offsetX: width * 0.2,
                width,
            } as Konva.TextConfig
        );
    }
    public tick(state: CrashRenderingState): void {
        // draw multiplier
        this.shapes.multiplier.text(state.multiplier.toFixed(2) + "x");
        super.tick(state);
    }

    protected statusChanged(state: CrashRenderingState): void {
        if (state.status === "finished") {
            // fire and forget
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.finished();
        } else if (state.status === "started" || state.status === "waiting") {
            this.restarted();
        }
    }

    private restarted() {
        this.shapes.subtitle.text("Current Payout");
        this.shapes.multiplier.fill("#ffffff");
    }

    private async finished() {
        this.shapes.subtitle.text("Game Over");
        this.shapes.multiplier.fill("#ff0400");
        if (this.configuration.displayMode === "simple") {
            return;
        }

        const multiplierAnimation = this.animate(
            {
                node: this.shapes.multiplier,
                duration: 0.4,
                // eslint-disable-next-line @typescript-eslint/unbound-method
                easing: Konva.Easings.StrongEaseIn,
                rotation: -4,
                scaleX: 1.04,
                scaleY: 1.04,
            }

        ).then(
            () => this.animate(

                {
                    node: this.shapes.multiplier,
                    duration: 0.5,
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    easing: Konva.Easings.BounceEaseOut,
                    rotation: 0,
                    scaleX: 1,
                    scaleY: 1,
                }
            )
        );

        const subtitleAnimation = this.animate(
            {
                node: this.shapes.subtitle,
                duration: 0.2,
                // eslint-disable-next-line @typescript-eslint/unbound-method
                easing: Konva.Easings.StrongEaseIn,
                rotation: 4,
            }
        ).then(
            () => this.animate(

                {
                    node: this.shapes.subtitle,
                    duration: 0.6,
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    easing: Konva.Easings.BounceEaseOut,
                    rotation: 0,
                }
            )
        );

        await Promise.all([ multiplierAnimation, subtitleAnimation ]);
    }
}
