import Konva from "konva";

export interface KonvaRenderingAnimationConfig extends Omit<Konva.TweenConfig, "onFinish" | "onUpdate"> {
    onFinish?: (tween: Konva.Tween) => void;
    onUpdate?: (tween: Konva.Tween) => void | undefined | boolean;
}

export interface KovaRenderingShapes<TConfig> {
    [index: string]: Konva.Group | Konva.Shape | KonvaRenderingLayer<TConfig>;
}

export interface KonvaRenderingState<T extends string | number = string> {
    status: T;
}

export abstract class KonvaRenderingLayer<
    TConfig = unknown,
    TShapes extends KovaRenderingShapes<TConfig> = any,
    TState extends KonvaRenderingState = KonvaRenderingState,
    TContainer extends Konva.Group = Konva.Group
> {
    public readonly container: TContainer;
    protected configuration!: TConfig;
    protected lastStatus?: TState["status"];
    protected shapes: TShapes;

    protected constructor(configuration: TConfig, container: TContainer, shapes?: TShapes) {
        this.container = container;
        this.configuration = configuration;
        this.shapes = shapes ?? {} as TShapes;
        for (const key in this.shapes) {
            if (Object.prototype.hasOwnProperty.call(this.shapes, key)) {
                const shape = this.shapes[key];
                if (!shape) {
                    continue;
                }
                if (typeof (shape as KonvaRenderingLayer).container !== "undefined") {
                    this.container.add((shape as KonvaRenderingLayer).container);
                    (shape as KonvaRenderingLayer).resize();
                    (shape as KonvaRenderingLayer).config(configuration);
                } else {
                    this.container.add(shape as (Konva.Group | Konva.Shape));
                }
            }
        }
    }

    public get width(): number {
        return this.container.width();
    }

    public get height(): number {
        return this.container.height();
    }

    public destroy(): void {
        for (const key in this.shapes) {
            if (Object.prototype.hasOwnProperty.call(this.shapes, key)) {
                const shape = this.shapes[key];
                if (!shape) {
                    continue;
                }

                if (shape instanceof Konva.Group) {
                    shape.destroyChildren();
                }

                shape.destroy();
            }
        }

        this.container.destroyChildren();
        this.container.destroy();
    }

    public resize(): void {
        for (const key in this.shapes) {
            if (Object.prototype.hasOwnProperty.call(this.shapes, key)) {
                const shape = this.shapes[key];
                if (!shape) {
                    continue;
                }

                if (typeof (shape as KonvaRenderingLayer).resize !== "undefined") {
                    (shape as KonvaRenderingLayer).resize();
                }
            }
        }
    }

    public setAttrs(config: Konva.ContainerConfig): this {
        this.container.setAttrs(config);
        return this;
    }

    public setAttr<K extends keyof Konva.ContainerConfig, V extends Konva.ContainerConfig[K]>(name: K, value: V): this {
        this.container.setAttr(name, value);
        return this;
    }

    public tick(state: TState): void {
        if (this.lastStatus !== state.status) {
            this.statusChanged(state, this.lastStatus);
            this.lastStatus = state.status;
        }
    }

    public config(configuration: TConfig): void {
        this.configuration = configuration;

        for (const key in this.shapes) {
            if (Object.prototype.hasOwnProperty.call(this.shapes, key)) {
                const shape = this.shapes[key];
                if (!shape) {
                    continue;
                }

                if (typeof (shape as KonvaRenderingLayer).config !== "undefined") {
                    (shape as KonvaRenderingLayer).config(configuration);
                }
            }
        }
    }

    protected addShape<K extends keyof TShapes>(name: K): this {
        const shape = this.shapes[name];
        if (!shape) {
            return this;
        }

        if (typeof (shape as KonvaRenderingLayer).container !== "undefined") {
            this.container.add((shape as KonvaRenderingLayer).container);
        } else {
            this.container.add(shape as (Konva.Group | Konva.Shape));
        }

        return this;
    }

    protected removeShape<K extends keyof TShapes>(name: K): this {
        const shape = this.shapes[name];
        if (!shape) {
            return this;
        }

        if (typeof (shape as KonvaRenderingLayer).container !== "undefined") {
            (shape as KonvaRenderingLayer).container.remove();
        } else {
            (shape as (Konva.Group | Konva.Shape)).remove();
        }

        return this;
    }

    protected animate(config: KonvaRenderingAnimationConfig, wait: 0): Konva.Tween
    protected animate(config: KonvaRenderingAnimationConfig, wait?: number): Promise<Konva.Tween>
    protected animate(config: KonvaRenderingAnimationConfig, wait?: number): Promise<Konva.Tween> | Konva.Tween {
        const getTween = (resolve?: (tween: Konva.Tween) => void): Konva.Tween => {
            const { onFinish, onUpdate, ...tweenConfig } = config;
            const tween = new Konva.Tween(
                {
                    ...tweenConfig as Konva.TweenConfig,
                    onFinish: () => {
                        tween.destroy();
                        if (onFinish) {
                            onFinish(tween);
                        }
                        if (resolve) {
                            resolve(tween);
                        }
                    },
                    onUpdate: () => {
                        let shouldStop = wait != null && tween.anim.frame.time >= wait * 1000;
                        if (onUpdate) {
                            if (onUpdate(tween) === false) {
                                shouldStop = true;
                                tween.destroy();
                            }
                        }
                        if (resolve != null && shouldStop) {
                            wait = undefined;
                            resolve(tween);
                        }
                    },
                }
            );
            return tween;
        };

        if (wait != null && wait <= 0) {
            wait = undefined;
            return getTween().play();
        } else {
            return new Promise<Konva.Tween>(
                (resolve) => getTween(resolve).play()
            );
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected statusChanged(state: TState, lastStatus: TState["status"] | undefined): void {
        // ignore
    }
}
