import "./crash-message-modal.style.scss";
import { PlanetItem } from "../../../crash-planet/crash-planet.component";
import React, { ReactNode } from "react";

export class CrashMessageModal extends React.PureComponent<{text: string}> {
    public render(): ReactNode {
        return <>
            <div className="crash-message-modal">
                <div>
                    <p>{this.props.text}</p>
                </div>
            </div>
            <PlanetItem className="crash-message-planet" type="orange" before="exclamation" />
        </>;
    }
}
