import { KonvaRenderingLayer, KonvaRenderingState } from "./konva-rendering-layer";
import Konva from "konva";

export class KonvaRenderingPageManager<
    TPages extends string,
    TLayer extends KonvaRenderingLayer<TConfig>,
    TConfig = unknown,
    TState extends KonvaRenderingState = KonvaRenderingState,
> extends KonvaRenderingLayer<TConfig, any, TState, Konva.Layer> {
    private readonly animationDuration: number;
    private pages: { [key in TPages]?: TLayer } = {};
    private lastAnimation?: Date;
    private currentPage?: TPages;
    private lastPage?: TPages;

    public constructor(configuration: TConfig, layer: Konva.Layer, animationDuration = 300) {
        super(configuration, layer.transformsEnabled("none"), animationDuration);
        this.animationDuration = animationDuration;
        this.config(configuration);
        this.resize();
    }

    public addPage(name: TPages, factory: (group: Konva.Group) => TLayer): void {
        if (typeof this.pages[name] !== "undefined") {
            throw new Error("Duplicate layer provided for a page.");
        }

        const page = factory(
            new Konva.Group(
                {
                    width: this.width,
                    height: this.height,
                    x: 0,
                    y: 0,
                    opacity: 0,
                }
            ).transformsEnabled("none")
        );

        page.config(this.configuration);
        this.pages[name] = page;
    }

    public fadeToPage(name: TPages): void {
        if (name === this.currentPage || typeof this.pages[name] === "undefined") {
            return;
        }

        this.lastAnimation = new Date();

        let page = this.lastPage ? this.pages[this.lastPage] as TLayer : undefined;
        if (page) {
            page?.container.opacity(0);
            page.container.remove();
        }

        this.lastPage = this.currentPage;
        page = this.lastPage ? this.pages[this.lastPage] as TLayer : undefined;
        if (page) {
            page?.container.opacity(1);
        }

        this.currentPage = name;
        page = this.pages[this.currentPage] as TLayer;
        page.container.opacity(0);
        this.container.add(page.container);
    }

    public config(configuration: TConfig): void {
        this.configuration = configuration;
        for (const key in this.pages) {
            if (Object.prototype.hasOwnProperty.call(this.pages, key)) {
                const page = this.pages[key];
                page?.config(configuration);
            }
        }
    }

    public resize(): void {
        const width = this.width;
        const height = this.height;
        for (const key in this.pages) {
            if (Object.prototype.hasOwnProperty.call(this.pages, key)) {
                const page = this.pages[key];
                page?.container.width(width);
                page?.container.height(height);
                page?.resize();
            }
        }
    }

    public destroy(): void {
        for (const name in this.pages) {
            if (Object.prototype.hasOwnProperty.call(this.pages, name)) {
                this.pages[name]?.destroy();
            }
        }
        this.pages = {};
        this.currentPage = undefined;
        this.lastPage = undefined;
        this.lastAnimation = undefined;
        this.container.destroy();
    }

    public tick(state: TState): void {
        if (!this.currentPage) {
            return;
        }

        const currentPage = this.pages[this.currentPage] as TLayer;
        if (!this.lastAnimation) {
            currentPage.tick(state);
            this.container.batchDraw();
            return;
        }

        const lastPage = this.lastPage ? this.pages[this.lastPage] as TLayer : undefined;

        let elapsed = (new Date().getTime() - this.lastAnimation.getTime()) / this.animationDuration;
        if (!this.lastPage || elapsed >= 1) {
            currentPage.container.opacity(1);
            currentPage.tick(state);

            lastPage?.container.remove();

            this.lastAnimation = undefined;
            this.container.batchDraw();
            return;
        }

        elapsed = (new Date().getTime() - this.lastAnimation.getTime()) / this.animationDuration;
        currentPage.container.opacity(elapsed);
        currentPage.tick(state);

        lastPage?.container.opacity(1 - elapsed);
        lastPage?.tick(state);
        this.container.batchDraw();
    }
}
