import "./crash-modal-manager.style.scss";
import * as Icon from "lucide-react";
import { Button } from "../buttons/button.component";
import { CrashFAQModal } from "./modals/crash-faq-modal/crash-faq-modal.component";
import { CrashHowToPlayModal } from "./modals/crash-how-to-play-modal/crash-how-to-play-modal.component";
import { CrashInfoModal } from "./modals/crash-info-modal/crash-info-modal.component";
import { CrashMessageModal } from "./modals/crash-message-modal/crash-message-modal.component";
import { CrashProvablyFairModal } from "./modals/crash-provably-fair-modal/crash-provably-fair-modal.component";
import { CrashSettingsModal } from "./modals/crash-settings-modal/crash-settings-modal.component";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Modal, ModalContext } from "../../contexts/modal.context";
import classNames from "classnames";
import React, { ReactNode } from "react";

export class CrashModalManager extends React.PureComponent<unknown, {
    currentModal?: Modal;
}> {
    public constructor(props: unknown) {
        super(props);
        this.state = {};
    }

    public render(): JSX.Element {
        const modal = this.state.currentModal;
        let element: ReactNode | undefined;
        let title: string | undefined;
        let isSmall = false;
        let isScrollable = true;
        let button: "top" | "bottom" = "bottom";
        switch (modal?.type) {
            case "faq":
                element = <CrashFAQModal {...(modal as Modal<"faq">).props} />;
                title = "Frequently Asked Questions";
                break;
            case "how-to-play":
                element = <CrashHowToPlayModal {...(modal as Modal<"how-to-play">).props} />;
                title = "How To Play";
                isSmall = true;
                break;
            case "information":
                element = <CrashInfoModal {...(modal as Modal<"information">).props} />;
                title = `Match #${(modal as Modal<"information">).props.matchId}`;
                isSmall = true;
                break;
            case "settings":
                element = <CrashSettingsModal {...(modal as Modal<"settings">).props} />;
                title = "Settings";
                isSmall = true;
                isScrollable = false;
                break;
            case "message":
                element = <CrashMessageModal {...(modal as Modal<"message">).props} />;
                title = "Oops!";
                isSmall = true;
                break;
            case "provably-fair":
                element = <CrashProvablyFairModal {...(modal as Modal<"provably-fair">).props} />;
                title = "Provably Fairness";
                button = "top";
                break;
        }

        const isVisible = !!modal && !!element;
        return <ModalContext.Provider
            value={
                {
                    openModal: (type, props) =>
                        this.setState(
                            {
                                currentModal: { type, props },
                            }
                        ),
                    currentModal: this.state.currentModal,
                }
            }
        >
            {this.props.children}
            <SwitchTransition
                mode="out-in"
            >
                <CSSTransition
                    key={modal?.type ?? "__empty"}
                    classNames="fade"
                    timeout={300}
                >
                    <>
                        {
                            isVisible &&
                            <div
                                className={
                                    classNames(
                                        "crash-modal",
                                        {
                                            ["crash-modal-small"]: isSmall,
                                            ["crash-modal-scrollable"]: isScrollable,
                                        }
                                    )
                                }
                            >
                                <div className="crash-modal-title">
                                    <h2>{title}</h2>
                                    {
                                        button === "top" && <Button
                                            onClick={
                                                () => this.setState(
                                                    {
                                                        currentModal: undefined,
                                                    }
                                                )
                                            }
                                            caption={<Icon.XCircle />}
                                        />
                                    }
                                </div>
                                <CSSTransition
                                    in={isVisible}
                                    timeout={300}
                                    classNames="fade"
                                    unmountOnExit={true}
                                >
                                    <article>{element}</article>
                                </CSSTransition>
                                {
                                    button === "bottom" && <div className="crash-modal-footer">
                                        <Button
                                            caption="OK"
                                            size="medium"
                                            color="blue"
                                            onClick={
                                                () => this.setState(
                                                    {
                                                        currentModal: undefined,
                                                    }
                                                )
                                            }
                                            hasSound={false}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </>
                </CSSTransition>
            </SwitchTransition>
            <CSSTransition
                in={isVisible}
                timeout={300}
                classNames="fade"
                unmountOnExit={true}
            >
                <span
                    className="crash-modal-overlay"
                    onClick={
                        () => this.setState(
                            {
                                currentModal: undefined,
                            }
                        )
                    }
                />
            </CSSTransition>
        </ModalContext.Provider>;
    }
}
