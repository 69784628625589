import { CrashMinimumXAxis, CrashMinimumYAxis, CrashXAxisHeight, CrashXOffset, CrashYAxisWidth, CrashYOffset } from "../crash-rendering.constants";
import { CrashRenderingConfig } from "../crash-rendering-config.interface";
import { CrashRenderingGameFlashLayer } from "./game-page-layers/crash-rendering-game-flash.layer";
import { CrashRenderingGameLineLayer } from "./game-page-layers/crash-rendering-game-line.layer";
import { CrashRenderingGameMultiplierLayer } from "./game-page-layers/crash-rendering-game-multiplier.layer";
import { CrashRenderingGameXAxisLayer } from "./game-page-layers/crash-rendering-game-xaxis.layer";
import { CrashRenderingGameYAxisLayer } from "./game-page-layers/crash-rendering-game-yaxis.layer";
import { CrashRenderingLayer } from "../crash-rendering-layer";
import { CrashRenderingState } from "../crash-rendering-state.interface";
import Konva from "konva";

export interface CrashRenderingGamePageState extends CrashRenderingState {
    xAxisRange: number;
    yAxisRange: number;
    xIncrement: number;
    yIncrement: number;
}

export class CrashRenderingGamePage extends CrashRenderingLayer<{
    xAxis: CrashRenderingGameXAxisLayer;
    yAxis: CrashRenderingGameYAxisLayer;
    line: CrashRenderingGameLineLayer;
    multiplier: CrashRenderingGameMultiplierLayer;
    flash: CrashRenderingGameFlashLayer;
    warning: Konva.Image;
}> {
    public constructor(configuration: CrashRenderingConfig, container: Konva.Group) {
        super(
            configuration,
            container,
            {
                xAxis: new CrashRenderingGameXAxisLayer(
                    configuration,
                    new Konva.Group(
                        {
                            height: CrashXAxisHeight,
                            offsetX: 0,
                            offsetY: 0,
                            x: CrashXOffset,
                        }
                    ).transformsEnabled("all")
                ),
                yAxis: new CrashRenderingGameYAxisLayer(
                    configuration,
                    new Konva.Group(
                        {
                            width: CrashYAxisWidth,
                            offsetX: CrashYAxisWidth,
                        }
                    ).transformsEnabled("all")
                ),
                line: new CrashRenderingGameLineLayer(
                    configuration,
                    new Konva.Group().transformsEnabled("position"),
                    (c) => this.shapes.flash.flash(c + "40", this.shapes.line.shuttle.absoluteX, this.shapes.line.shuttle.absoluteY, true)
                ),
                multiplier: new CrashRenderingGameMultiplierLayer(
                    configuration,
                    new Konva.Group(
                        {
                            x: CrashXOffset,
                            y: CrashYOffset,
                        }
                    ).transformsEnabled("position")
                ),
                flash: new CrashRenderingGameFlashLayer(
                    configuration,
                    new Konva.Group(
                        {
                            x: 0,
                            y: 0,
                        }
                    ).transformsEnabled("none")
                ),
                warning: new Konva.Image(
                    {
                        image: new Image(),
                        width: 40,
                        height: 40,
                        offsetX: 0,
                        offsetY: 20,
                        x: CrashXOffset,
                        y: CrashYOffset,
                        opacity: 0,
                    }
                ),
            }
        );

        (this.shapes.warning.image() as HTMLImageElement).src = "/offline.svg?v=1";

        this.config(configuration);
        this.resize();
    }

    public get plotWidth(): number {
        return this.width - (CrashYAxisWidth + CrashXOffset);
    }

    public get plotHeight(): number {
        return this.height - (CrashXAxisHeight + CrashYOffset);
    }

    public config(configuration: CrashRenderingConfig): void {
        if (configuration.displayMode === "rich") {
            this.addShape("flash");
        } else {
            this.removeShape("flash");
        }

        super.config(configuration);
    }

    public resize(): void {
        const width = this.width;
        const height = this.height;
        const plotWidth = this.plotWidth;
        const plotHeight = this.plotHeight;

        this.shapes.xAxis.setAttrs(
            {
                width: plotWidth,
                height: CrashXAxisHeight,
                y: CrashYOffset + plotHeight,
            }
        );

        this.shapes.yAxis.setAttrs(
            {
                x: width,
                y: CrashYOffset + plotHeight,
                height: plotHeight,
                offsetY: plotHeight,
            }
        );

        this.shapes.line.setAttrs(
            {
                width: plotWidth,
                height: plotHeight,
                offsetX: plotWidth / 2,
                offsetY: plotHeight / 2,
                x: CrashXOffset + plotWidth / 2,
                y: CrashYOffset + plotHeight / 2,
            }
        );

        this.shapes.multiplier.setAttrs(
            {
                width: plotWidth,
                height: plotHeight,
            }
        );

        this.shapes.flash.setAttrs(
            {
                width,
                height,
            }
        );

        super.resize();
    }

    public tick(state: CrashRenderingState): void {
        super.tick(state);

        if (state.isLagging) {
            this.shapes.warning.opacity(1);
        } else {
            this.shapes.warning.opacity(0);
        }

        this.shapes.multiplier.tick(state);
        this.shapes.flash.tick(state);

        const xAxis = Math.max(state.elapsed, CrashMinimumXAxis);
        const yAxis = Math.max(state.multiplier, CrashMinimumYAxis);
        const pageState: CrashRenderingGamePageState = {
            ...state,
            xIncrement: this.plotWidth / xAxis,
            yIncrement: this.plotHeight / yAxis,
            xAxisRange: xAxis,
            yAxisRange: yAxis,
        };

        this.shapes.xAxis.tick(pageState);
        this.shapes.yAxis.tick(pageState);
        this.shapes.line.tick(pageState);
    }

    protected statusChanged(state: CrashRenderingState): void {
        if (state.status !== "finished" || this.configuration.displayMode === "simple") {
            return;
        }
        // fire and forget
        void this.finished();
    }

    private async finished() {
        this.shapes.flash.flash(
            "#ffffffbb",
            this.shapes.line.shuttle.absoluteX,
            this.shapes.line.shuttle.absoluteY,
            false
        );

        const xAxisAnimation = this.animate(
            {
                node: this.shapes.xAxis.container,
                duration: 0.3,
                // eslint-disable-next-line @typescript-eslint/unbound-method
                easing: Konva.Easings.StrongEaseIn,
                rotation: 1,
            }
        ).then(
            () => this.animate(

                {
                    node: this.shapes.xAxis.container,
                    duration: 1,
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    easing: Konva.Easings.BounceEaseOut,
                    rotation: 0,
                }
            )
        );

        const yAxisAnimation = this.animate(
            {
                node: this.shapes.yAxis.container,
                duration: 0.2,
                // eslint-disable-next-line @typescript-eslint/unbound-method
                easing: Konva.Easings.StrongEaseIn,
                rotation: 1,
            }
        ).then(
            () => this.animate(

                {
                    node: this.shapes.yAxis.container,
                    duration: 0.8,
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    easing: Konva.Easings.BounceEaseOut,
                    rotation: 0,
                }
            )
        );

        await Promise.all([ xAxisAnimation, yAxisAnimation ]);
    }
}
