import "./crash-settings-modal.style.scss";
import { Button } from "../../../buttons/button.component";
import { CrashContext, CrashContextValue } from "../../../../contexts/crash.context";
import { PlanetItem } from "../../../crash-planet/crash-planet.component";
import React from "react";

export class CrashSettingsModal extends React.PureComponent {
    public render(): JSX.Element {
        return <CrashContext.Consumer>
            {
                (context) => <>
                    <div className="crash-settings-modal">
                        {/* <div>
                                <Button
                                    caption={this.props.isFullscreen ? <Icons.Maximize width={20} /> : <Icons.Minimize width={20} />}
                                    onClick={this.props.toggleFullscreen}
                                    isTransparent
                                    size="small"
                                />
                            </div> */}
                        <div>
                            <Button
                                caption={!context.configuration?.isMuted ? "Sound Effects Enabled" : "Sound Effects Disabled"}
                                onClick={() => this.toggleSound(context)}
                                isActive={!context.configuration?.isMuted}
                                color="blue"
                            />
                        </div>
                        <div>
                            <Button
                                caption={context.configuration?.displayMode === "rich" ? "High Graphics Enabled" : "High Graphics Disabled"}
                                onClick={() => this.switchDisplayMode(context)}
                                isActive={context.configuration?.displayMode === "rich"}
                                color="blue"
                            />
                        </div>
                    </div>
                    <PlanetItem className="crash-settings-planet" type="purple" before="hash" />
                </>
            }
        </CrashContext.Consumer>;
    }

    private switchDisplayMode = (context: CrashContextValue) => {
        if (!context.setConfiguration || !context.configuration) {
            return;
        }

        context.setConfiguration(
            {
                ...(context.configuration),
                displayMode: context.configuration.displayMode === "rich" ? "simple" : "rich",
            }
        );
    };

    private toggleSound = (context: CrashContextValue) => {
        if (!context.setConfiguration || !context.configuration) {
            return;
        }

        context.setConfiguration(
            {
                ...(context.configuration),
                isMuted: !context.configuration.isMuted,
            }
        );
    };
}
