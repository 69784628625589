import * as React from "react";
import { AppProperties } from "./app.properties";
import { GameConfiguration, GameTypes } from "@tgg/shared";
import CrashPage from "./components/crash-page/crash.page";

const CurrencyShortForm: { [index: string]: string } = {
    USD: "$", // US Dollar
    EUR: "€", // Euro
    CRC: "₡", // Costa Rican Colón
    GBP: "£", // British Pound Sterling
    ILS: "₪", // Israeli New Sheqel
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    KRW: "₩", // South Korean Won
    NGN: "₦", // Nigerian Naira
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    THB: "฿", // Thai Baht
    UAH: "₴", // Ukrainian Hryvnia
    VND: "₫", // Vietnamese Dong
};

export default class App extends React.PureComponent<AppProperties, {
    userId?: number;
    screenHeight?: number;
    balance?: number;
    currency?: string;
    config?: GameConfiguration;
}> {
    public constructor(props: AppProperties) {
        super(props);
        this.state = { };
    }

    public componentDidMount(): void {
        const url = new URL(window.location.href);
        const marbleId = url.searchParams.get("playerToken");

        if (marbleId) {
            this.props.sessionService.getOrCreateSession(marbleId, GameTypes.Crash).then(
                (s) => {
                    this.props.financeService.onBalanceUpdated.sub(
                        (b) => this.setState(
                            {
                                balance: b.amount,
                                currency: b.currency && typeof CurrencyShortForm[b.currency] !== "undefined" ? CurrencyShortForm[b.currency] : b.currency,
                            }
                        )
                    );
                    this.props.crashService.setToken(s.token);
                    this.props.crashApiService.setToken(s.token);
                    this.props.financeService.setToken(s.token);
                    void this.props.financeService.connectWebSocket();
                    this.setState(
                        {
                            userId: s.userId,
                            config: s.config ?? undefined,
                        }
                    );
                },
                (e: string | Error) => alert(`Failed to authenticate: ${e.toString()}`)
            );
        }

        window.addEventListener("resize", this.onResize);
        this.onResize();
    }

    public componentWillUnmount(): void {
        this.props.financeService.onBalanceUpdated.clear();
        window.removeEventListener("resize", this.onResize);
    }

    public render(): React.ReactNode {
        return <CrashPage
            userId={this.state.userId}
            crashService={this.props.crashService}
            crashApiService={this.props.crashApiService}
            balance={this.state.balance}
            currency={this.state.currency}
            config={this.state.config}
            style={{ ["--screen-height" as any]: this.state.screenHeight ? `${this.state.screenHeight}px` : "100vh" }}
        ></CrashPage>;
    }

    private onResize = () => {
        this.setState(
            {
                screenHeight: window.innerHeight,
            }
        );
    };
}
