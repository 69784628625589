import "./button.style.scss";
import { AudioHelper } from "@tgg/shared";
import { ButtonProperties } from "./button.properties";
import classnames from "classnames";
import React, { MouseEvent } from "react";

export class Button extends React.PureComponent<ButtonProperties, unknown> {
    private clickAudio: HTMLAudioElement | undefined;
    public componentDidMount(): void {
        void AudioHelper.loadAudio("/button-click.mp3").then(
            (a) => this.clickAudio = a
        );
    }

    public render(): JSX.Element {
        const {
            caption,
            isDisabled,
            isTransparent,
            className,
            size,
            isLoading,
            isActive = false,
            color = "none",
        } = this.props;

        return <button
            className={
                classnames(
                    className,
                    "button",
                    color !== "none" ? color : undefined,
                    {
                        loading: isLoading,
                        disabled: isDisabled || isLoading,
                        transparent: isTransparent,
                        active: isActive,
                        small: size && size === "small",
                        medium: size && size !== "small",
                        normal: !size,
                    }
                )
            }
            disabled={isDisabled || isLoading}
            onClick={this.onButtonClick}
        >
            {caption}
        </button>;
    }

    private onButtonClick = async (e: MouseEvent) => {
        e.stopPropagation();
        if (this.props.onClick && !this.props.isDisabled) {
            if (this.props.hasSound) {
                AudioHelper.playAudio(this.clickAudio);
            }
            await this.props.onClick(e);
        }
    };
}
