export abstract class ImageHelper {
    public static loadImage(address: string): Promise<HTMLImageElement> {
        return new Promise<HTMLImageElement>(
            (resolve, reject) => {
                const image = new Image();
                image.onload = () => resolve(image);
                image.onerror = () => reject("Failed to load image.");
                image.src = address;
            }
        );
    }
}
