import "./crash-info-modal.style.scss";
import { CrashContext } from "../../../../contexts/crash.context";
import { PlanetItem } from "../../../crash-planet/crash-planet.component";
import { ProvablyFairHashString } from "../crash-provably-fair-modal/components/provably-fair-hash-string/provably-fair-hash-string.component";
import React from "react";

export class CrashInfoModal extends React.PureComponent<
{
    matchId: number;
}> {
    public render(): JSX.Element {
        const matchId = this.props.matchId;

        return <CrashContext.Consumer>
            {
                (context) => {
                    const currentMatch = context.currentMatch?.id === matchId ?
                        context.currentMatch :
                        context.lastMatches.find((m) => m.id === matchId);
                    const nextMatch = context.currentMatch?.id === matchId ?
                        context.currentMatch :
                        context.lastMatches.find((m) => m.id === matchId);

                    return <>
                        <div className="crash-information-modal">
                            <div>
                                <label>Match ID</label>
                                <span>{matchId}</span>
                            </div>
                            <div>
                                <label>Salt</label>
                                <ProvablyFairHashString hash={currentMatch?.salt}></ProvablyFairHashString>
                            </div>
                            <div>
                                <label>Hashed Secret</label>
                                <ProvablyFairHashString hash={currentMatch?.hashedValue}></ProvablyFairHashString>
                            </div>
                            <div>
                                <label>Secret</label>
                                <ProvablyFairHashString hash={nextMatch?.hashedValue}></ProvablyFairHashString>
                            </div>
                        </div>
                        <PlanetItem className="crash-information-planet" type="purple" before="hash" />
                    </>;
                }
            }
        </CrashContext.Consumer>;
    }
}
