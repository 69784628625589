import "./crash-participants.style.scss";
import * as Icons from "lucide-react";
import { AccountingHelper } from "@tgg/shared";
import { Button } from "../buttons/button.component";
import { CrashContext, CrashContextValue } from "../../contexts/crash.context";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ModalContext } from "../../contexts/modal.context";
import { WsCrashParticipantDto } from "../../services/crash/dtos/ws-crash-participant.dto";
import classnames from "classnames";
import React from "react";
import UserIcon from "./assets/man.svg";

export class CrashParticipant extends React.PureComponent<{
    participants: WsCrashParticipantDto[];
    userId?: number;
    finished: boolean;
}, {
    participant?: WsCrashParticipantDto;
    lastParticipants: WsCrashParticipantDto[];
}> {
    public constructor(props: CrashParticipant["props"]) {
        super(props);
        this.state = {
            lastParticipants: this.props.participants ?? [],
        };
    }

    public componentDidUpdate(oldProps: CrashParticipant["props"]): void {
        if (!this.props.finished || !oldProps.finished) {
            this.setState(
                {
                    lastParticipants: this.props.participants ?? [],
                    participant: this.props.participants.find((p) => p.userId === this.props.userId),
                }
            );
        }
    }

    public render(): JSX.Element {
        const participants = this.props.finished ? this.state.lastParticipants : (this.props.participants ?? []);
        const currentParticipant = this.state.participant;
        const hasWon = !!currentParticipant && !!currentParticipant.cashOut && !!currentParticipant.multiplier;
        const hasLost = !!currentParticipant && !currentParticipant.cashOut && this.props.finished;

        return <CrashContext.Consumer>
            {
                (crashContext) => <div className={
                    classnames(
                        "crashParticipants",
                        {
                            won: hasWon,
                            loss: hasLost,
                        }
                    )
                }>
                    <header>
                        <ModalContext.Consumer>
                            {
                                (modalContext) => <Button
                                    caption={<Icons.ShieldCheck width={20} />}
                                    onClick={() => modalContext.openModal("information", { matchId: crashContext.currentMatch!.id })}
                                    isDisabled={!crashContext.currentMatch}
                                    isTransparent
                                    size="small"
                                />
                            }
                        </ModalContext.Consumer>
                        <h4>{participants.length} Players</h4>
                        <span>
                            {crashContext.currency} {AccountingHelper.formatMoney(participants.reduce((a, b) => a + b.deposit, 0))}
                        </span>
                    </header>
                    <main>
                        <TransitionGroup appear enter>
                            {participants.sort((a, b) => b.deposit - a.deposit).map((p) => this.renderParticipant(p, crashContext))}
                        </TransitionGroup>
                    </main>
                </div>
            }
        </CrashContext.Consumer>;
    }

    private renderParticipant(participant: WsCrashParticipantDto, context: CrashContextValue) {
        const hasWon = !!participant.cashOut;
        const hasLost = !participant.cashOut && this.props.finished;

        return <CSSTransition
            classNames="fade"
            key={`participant-${participant.userId}`}
            timeout={300}
        >
            <div className={
                classnames(
                    {
                        won: hasWon,
                        lost: hasLost,
                    }
                )
            }>
                {/* //TODO user profile picture */}
                <img src={UserIcon} alt={participant.userId.toString()} />
                <div>
                    User {participant.userId}
                </div>
                {hasWon ? <span>{participant.multiplier?.toFixed(2)}x</span> : null}
                <span>
                    {
                        // (hasWon ? "+" : (hasLost ? "-" : "")) +
                        (context.currency ?? "") + " " +
                        AccountingHelper.formatMoney(
                            (participant.cashOut && !!participant.multiplier) ? (participant.deposit * participant.multiplier) : participant.deposit
                        )
                    }
                </span>
            </div>
        </CSSTransition>;
    }
}
