import "./crash-page.style.scss";
import { CrashApiService } from "../../services/crash/crash-api.service";
import { CrashBetPlacement } from "../crash-bet-placement/crash-bet-placement.component";
import { CrashContext } from "../../contexts/crash.context";
import { CrashFooter } from "../crash-footer/crash-footer.component";
import { CrashGameEngine, CrashMatchState } from "./crash-game-engine";
import { CrashHistory } from "../crash-history/crash-history.component";
import { CrashModalManager } from "../crash-modals/crash-modal-manager.component";
import { CrashParticipant } from "../crash-participants/crash-participants.component";
import { CrashRenderer } from "../crash-renderer/crash-renderer.component";
import { CrashRenderingConfig } from "../crash-renderer/crash-rendering/crash-rendering-config.interface";
import { CrashService } from "../../services/crash/crash.service";
import { CrashSplash } from "../crash-splash/crash-splash.components";
import { DefaultMaxAmount, DefaultMinAmount } from "../../constants/crash.constant";
import { GameConfiguration } from "@tgg/shared/src";
import { ModalContext } from "../../contexts/modal.context";
import { PlanetItem } from "../crash-planet/crash-planet.component";
import { WsCrashMatchDto } from "../../services/crash/dtos/ws-crash-match.dto";
import { WsCrashMatchStatusDto } from "../../services/crash/dtos/ws-crash-match-status.dto";
import { WsCrashParticipantDto } from "../../services/crash/dtos/ws-crash-participant.dto";
import classnames from "classnames";
import Konva from "konva";
import React, { CSSProperties } from "react";
import screenfull from "screenfull";

export default class CrashPage extends React.PureComponent<{
    crashService: CrashService;
    crashApiService: CrashApiService;
    config?: GameConfiguration;
    userId?: number;
    balance?: number;
    currency?: string;
    style?: CSSProperties;
}, {
    configuration: CrashRenderingConfig;
    currentMatch?: WsCrashMatchDto;
    lastMatches: WsCrashMatchDto[];
    currentParticipants: WsCrashParticipantDto[];
    hasWon: boolean;
    hasLost: boolean;
}> {
    private currentState: CrashMatchState = {};
    private engine: CrashGameEngine;
    private konvaPixelRatio?: number;
    private autoMute = false;

    public constructor(props: CrashPage["props"]) {
        super(props);
        this.state = {
            configuration: {
                displayMode: "rich",
                isMuted: true,
            },
            currentParticipants: [],
            lastMatches: [],
            hasWon: false,
            hasLost: false,
        };

        this.engine = new CrashGameEngine(() => this.currentState);
    }

    public componentDidMount(): void {
        this.props.crashService.onMatchesAvailable.sub(this.matchesAvailable);
        this.props.crashService.onMatchUpdated.sub(this.matchUpdated);
        this.props.crashService.onMatchStatusUpdated.sub(this.matchStatusUpdated);
        this.props.crashService.onMatchParticipantsUpdated.sub(this.participantsUpdated);
        this.engine.onMatchStatusChanged.subscribe(this.onStateChanged);
        void this.props.crashService.connectWebSocket();

        if (typeof document !== "undefined") {
            document.addEventListener("visibilitychange", this.onTabVisibilityChanged);
        }
    }

    public componentWillUnmount(): void {
        if (typeof document !== "undefined") {
            document.removeEventListener("visibilitychange", this.onTabVisibilityChanged);
        }

        this.engine.onMatchStatusChanged.unsub(this.onStateChanged);
        this.props.crashService.onMatchesAvailable.unsub(this.matchesAvailable);
        this.props.crashService.onMatchUpdated.unsub(this.matchUpdated);
        this.props.crashService.onMatchStatusUpdated.unsub(this.matchStatusUpdated);
        this.props.crashService.onMatchParticipantsUpdated.unsub(this.participantsUpdated);
    }

    public render(): JSX.Element {
        const isFullscreen = this.isFullscreen;
        const state = this.engine.getLastState();
        const currentParticipant = this.state.currentParticipants.find((p) => p.userId === this.props.userId);
        const isInGame = state.status === "started" || state.status === "waiting";
        const isPlanetActive = !!currentParticipant && isInGame;
        const isPlanetHappy = isPlanetActive && currentParticipant?.cashOut != null;

        return <article className="crash" style={this.props.style}>
            <CrashSplash onLoaded={this.onLoaded} />
            <CrashContext.Provider
                value={
                    {
                        balance: this.props.balance,
                        currency: this.props.currency,
                        lastMatches: this.state.lastMatches,
                        status: state.status,
                        crashApiService: this.props.crashApiService,
                        currentMatch: this.state.currentMatch,
                        participant: this.state.currentParticipants.find((p) => p.userId === this.props.userId),
                        participants: this.state.currentParticipants,
                        userId: this.props.userId,
                        configuration: this.state.configuration,
                        maxDeposit: this.props.config?.MaxDeposit ?? DefaultMaxAmount,
                        minDeposit: this.props.config?.MinDeposit ?? DefaultMinAmount,
                        setConfiguration: this.setConfiguration,
                        isFullscreen: this.isFullscreen,
                        toggleFullscreen: this.toggleFullscreen,
                    }
                }
            >
                <CrashModalManager>
                    <ModalContext.Consumer>
                        {
                            (modalContext) =>
                                <div
                                    className={
                                        classnames(
                                            "crashGamePage",
                                            {
                                                fullscreen: isFullscreen,
                                                loading: state.status === "idle",
                                                noPlanet: (state.status === "finished" && this.state.configuration.displayMode === "rich"),
                                            }
                                        )
                                    }
                                >
                                    <div>
                                        <PlanetItem
                                            className="purple-planet"
                                            type="purple"
                                            isAnimate
                                            isActive={isInGame ? isPlanetActive : undefined}
                                            isJumping={isPlanetHappy}
                                            shrink={modalContext.currentModal != null || this.state.configuration.displayMode === "simple"}
                                        />
                                        <CrashParticipant
                                            finished={state.status === "finished"}
                                            participants={this.state.currentParticipants}
                                            userId={this.props.userId}
                                        />
                                        <CrashRenderer
                                            configuration={this.state.configuration}
                                            engine={this.engine}
                                        />
                                        <CrashBetPlacement
                                            cashoutMatch={this.cashoutMatch}
                                            joinMatch={this.joinMatch}
                                            status={state.status}
                                        />
                                        <CrashHistory />
                                        <CrashFooter />
                                        <PlanetItem
                                            className="orange-planet"
                                            type="orange"
                                            isAnimate
                                            isActive={isInGame ? isPlanetActive : undefined}
                                            isJumping={isPlanetHappy}
                                            shrink={modalContext.currentModal != null || this.state.configuration.displayMode === "simple"}
                                        />
                                        <PlanetItem
                                            className="green-planet"
                                            type="green"
                                            isAnimate
                                            isActive={isInGame ? isPlanetActive : undefined}
                                            isJumping={isPlanetHappy}
                                            shrink={modalContext.currentModal != null || this.state.configuration.displayMode === "simple"}
                                        />
                                    </div>
                                </div>
                        }
                    </ModalContext.Consumer>
                </CrashModalManager>
            </CrashContext.Provider>
        </article>;
    }

    private get isFullscreen() {
        if (typeof document !== "object") {
            return false;
        }

        if (screenfull.isEnabled) {
            return screenfull.isFullscreen;
        }

        return false;
    }

    private toggleFullscreen = async () => {
        if (typeof document !== "object") {
            return;
        }

        if (screenfull.isEnabled) {
            return screenfull.request();
        }

        this.forceUpdate();
    };

    private onStateChanged = () => {
        this.forceUpdate();
    };

    private setConfiguration = (configuration: CrashRenderingConfig) => {
        this.setState(
            {
                configuration,
            }
        );

        if (configuration.displayMode === "rich") {
            Konva.pixelRatio = this.konvaPixelRatio ?? 1;
        } else {
            if (this.konvaPixelRatio == null && Konva.pixelRatio > 1) {
                this.konvaPixelRatio = Konva.pixelRatio;
            }
            Konva.pixelRatio = 1;
        }
    };

    private onLoaded = () => {
        this.setConfiguration(
            {
                isMuted: false,
                displayMode: "rich",
            }
        );
    };

    private updateEngine = () => {
        this.engine.updateState(
            this.state.currentMatch,
            this.state.lastMatches?.find((x) => x.end != null),
            this.state.currentParticipants.find((p) => p.userId === this.props.userId)
        );
    };

    private joinMatch = async (matchId: number, amount: number, multiplier?: number) => {
        if (!this.props.userId) {
            return;
        }

        try {
            const participant = await this.props.crashService.joinCrashMatch(matchId, amount, multiplier);
            this.participantsUpdated([ participant ]);
        } catch (error) {
            alert(error);
        }
    };

    private cashoutMatch = async (matchId: number) => {
        if (!this.props.userId) {
            return;
        }

        try {
            const participant = await this.props.crashService.cashoutCrashMatch(matchId);
            this.participantsUpdated([ participant ]);
        } catch (error) {
            alert(error);
        }
    };

    private participantsUpdated = (participants: WsCrashParticipantDto[]) => {
        if (!this.state.currentMatch) {
            return;
        }

        let currentParticipants = [ ...this.state.currentParticipants ];
        for (const participant of participants) {
            if (participant.matchId !== this.state.currentMatch.id) {
                continue;
            }

            let isFound = false;
            currentParticipants = currentParticipants.map(
                (p) => {
                    if (p.userId === participant?.userId) {
                        isFound = true;
                        return participant;
                    }

                    return p;
                }
            );

            if (!isFound) {
                currentParticipants?.push(participant);
            }
        }

        this.setState(
            {
                currentParticipants: participants,
            },
            this.updateEngine
        );
    };

    private matchStatusUpdated = (status: WsCrashMatchStatusDto) => {
        this.currentState.multiplier = status.m;
        this.currentState.started = status.e >= 0;
        this.currentState.crashed = status.c ?? undefined;
        this.currentState.elapsed = status.e;
        this.currentState.matchId = status.i;
        this.currentState.updated = new Date();
    };

    private matchUpdated = (match: WsCrashMatchDto) => {
        const lastMatches = [ ...(this.state.lastMatches ?? []) ];
        if (match.end) {
            lastMatches.unshift(match);
            while (lastMatches.length > 50) {
                lastMatches.pop();
            }
        }

        const delta = match.elapsed - (new Date(match.start).getTime() - new Date().getTime());
        const end = !match.end ? undefined : (new Date(match.start).getTime() - new Date().getTime()) - delta;
        this.currentState.started = match.elapsed >= 0;
        this.currentState.crashed = end;
        this.currentState.elapsed = match.elapsed;
        this.currentState.matchId = match.id;
        this.currentState.updated = new Date();

        this.setState(
            {
                currentMatch: match,
                lastMatches,
                currentParticipants: match.participants,
            },
            this.updateEngine
        );
    };

    private matchesAvailable = (lastMatches: WsCrashMatchDto[]) => {
        this.setState(
            {
                lastMatches,
            },
            this.updateEngine
        );
    };

    private onTabVisibilityChanged = () => {
        if (!document || typeof document.hidden !== "boolean") {
            return;
        }

        if (!!document.hidden && !this.state.configuration.isMuted) {
            this.autoMute = true;
            this.setConfiguration(
                {
                    ...this.state.configuration,
                    isMuted: true,
                }
            );
        } else if (this.autoMute) {
            this.autoMute = false;
            this.setConfiguration(
                {
                    ...this.state.configuration,
                    isMuted: false,
                }
            );
        }
    };
}
