import "./crash-how-to-play-modal.style.scss";
import { PlanetItem } from "../../../crash-planet/crash-planet.component";
import React, { ReactNode } from "react";

export class CrashHowToPlayModal extends React.PureComponent {
    public render(): ReactNode {
        return <>
            <div className="crash-how-to-play-modal">
                <div>
                    <p>
                        Space Cowboy is known as a &apos;Crash&apos; game. To win at Space Cowboy, you must select an amount you wish to bet and Cash Out before the rocket explodes.
                        If you Cash Out before the rocket explodes, you will win the amount of money that you staked, multiplied by the current multiplier shown on screen at the time of your cash out.
                        So, for example, if you bet 10 and cash out at 1.10x, you will win 10.10.
                        If you do not Cash Out before the rocket explodes, you will lose your stake and a new round will begin.
                    </p>
                    <p>
                        <b>Auto Cash-Out</b>
                        <br />
                        You can select an amount that you wish to have the game automatically Cash Out for you.
                        Simply enter the amount (VALUE OF CURRENCY) into this box and the game will automatically Cash Out when the multiplier is high enough to pay you this amount of winnings.
                    </p>
                </div>
            </div>
            <PlanetItem className="crash-how-to-play-planet" type="orange" before="exclamation" />
        </>;
    }
}
