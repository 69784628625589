import { AudioHelper } from "@tgg/shared/src";
import { CrashBigStars, CrashSmallStars, CrashThemeAudioAddress } from "../crash-rendering.constants";
import { CrashRenderingConfig } from "../crash-rendering-config.interface";
import { CrashRenderingLayer } from "../crash-rendering-layer";
import { CrashRenderingState } from "../crash-rendering-state.interface";
import { MinimumMultiplier } from "../../../../constants/crash.constant";
import { Vector2d } from "konva/types/types";
import Konva from "konva";

export class CrashRenderingBackground extends CrashRenderingLayer<
{
    rectangle: Konva.Rect;
}, CrashRenderingState, Konva.Layer> {
    private stars: Konva.Circle[] = [];
    private direction: Vector2d = { x: -1, y: 1 };
    private lastMultiplier = MinimumMultiplier;
    private themeAudio: HTMLAudioElement | undefined;

    public constructor(configuration: CrashRenderingConfig, container: Konva.Layer) {
        super(
            configuration,
            container.transformsEnabled("none"),
            {
                rectangle: new Konva.Rect(
                    {
                        fillEnabled: true,
                        x: 0,
                        y: 0,
                        fillRadialGradientColorStops: [ 0, "#000000", 1, "#021623" ],
                    }
                ).transformsEnabled("position"),
            }
        );

        for (let i = 0; i < CrashSmallStars; i++) {
            const radius = Math.random() * 3;
            const star = new Konva.Circle(
                {
                    radius,
                    fillEnabled: true,
                    fillRadialGradientStartPoint: { x: 0, y: 0 },
                    fillRadialGradientStartRadius: radius,
                    fillRadialGradientEndPoint: { x: 0, y: 0 },
                    fillRadialGradientEndRadius: radius / 5,
                    fillRadialGradientColorStops: [ 0, "transparent", 1, "#ffffff40" ],
                }
            );

            star.transformsEnabled("position");
            star.listening(false);
            star.cache({ pixelRatio: 1 });
            this.stars.push(star);
        }

        for (let i = 0; i < CrashBigStars; i++) {
            const radius = (Math.random() * 3) + 1;
            const star = new Konva.Circle(
                {
                    radius,
                    fillEnabled: true,
                    fillRadialGradientStartPoint: { x: 0, y: 0 },
                    fillRadialGradientStartRadius: radius,
                    fillRadialGradientEndPoint: { x: 0, y: 0 },
                    fillRadialGradientEndRadius: radius / 5,
                    fillRadialGradientColorStops: [ 0, "transparent", 0.6, "#FFFFFFA0" ],
                }
            );

            star.transformsEnabled("position");
            star.listening(false);
            star.cache({ pixelRatio: 1 });
            this.stars.push(star);
        }

        this.config(configuration);
        this.resize();
    }

    public config(configuration: CrashRenderingConfig): void {
        if (configuration.displayMode === "rich") {
            for (const star of this.stars ?? []) {
                this.container.add(star);
            }
        } else {
            this.shapes.rectangle.fillRadialGradientEndPoint(
                {
                    x: this.width * -0.2,
                    y: this.height * 1.2,
                }
            );

            for (const star of this.stars ?? []) {
                star.remove();
            }
        }

        if (!configuration.isMuted) {
            if (this.themeAudio == null) {
                AudioHelper.loadAudio(CrashThemeAudioAddress).then(
                    (e) => {
                        e.volume = 0.3;
                        e.loop = true;
                        this.themeAudio = e;
                        void this.themeAudio.play();
                    },
                    () => {
                        // ignore
                    }
                );
            } else {
                void this.themeAudio.play();
            }
        } else {
            this.themeAudio?.pause();
        }

        super.config(configuration);
    }

    public resize(): void {
        const width = this.width;
        const height = this.height;

        this.shapes.rectangle.setAttrs(
            {
                width,
                height,
                fillRadialGradientStartPoint: { x: width * 0.4, y: height * 0.6 },
                fillRadialGradientStartRadius: width,
                fillRadialGradientEndRadius: width / 15,
                fillRadialGradientEndPoint: { x: width * -0.2, y: height * 1.2 },
            } as Konva.RectConfig
        );

        for (const star of this.stars) {
            star.x(Math.random() * width);
            star.y(Math.random() * height);
        }

        this.container.batchDraw();
        super.resize();
    }

    public destroy(): void {
        for (const star of this.stars) {
            star.destroy();
        }

        super.resize();
    }

    public tick(state: CrashRenderingState): void {
        super.tick(state);
        if (this.configuration.displayMode !== "rich") {
            return;
        }

        // draw background
        const width = this.width;
        const height = this.height;
        const multiplier = state.multiplier;
        if (multiplier >= this.lastMultiplier || this.lastMultiplier < 1.1) {
            this.lastMultiplier = multiplier;
        } else {
            this.lastMultiplier = Math.max(this.lastMultiplier / 1.05, 1);
        }

        // move glow
        this.shapes.rectangle.fillRadialGradientEndPoint(
            {
                x: width * (-0.2 - Math.min((this.lastMultiplier - 1), 50) / 10),
                y: height * (1.2 + Math.min((this.lastMultiplier - 1), 50) / 10),
            }
        );

        // move stars
        const speed = Math.max(Math.min((this.lastMultiplier - 1) * 0.1, 20), 0.02);
        for (const star of this.stars) {
            const pos = star.position();

            let x = pos.x + this.direction.x * speed * star.radius();
            let y = pos.y + this.direction.y * speed * star.radius();
            if (pos.y < -5 || pos.y > (height + 5)) {
                y = this.direction.y < 0 ? height + 3 : -3;
            }

            if (pos.x < -5 || pos.x > (width + 5)) {
                x = this.direction.x < 0 ? width + 3 : -3;
            }

            star.position({ x, y });
        }

        this.container.batchDraw();
    }
}
