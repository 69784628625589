/* eslint-disable max-classes-per-file */
import "./table.style.scss";
import { Button } from "../buttons/button.component";
import { ComponentHelper } from "@tgg/shared";
import { TableCellProperties, TableHeaderCellProperties, TableHeaderProperties, TableProperties, TableRowProperties } from "./table.properties";
import classNames from "classnames";
import React from "react";

interface TableContext {
    isDisabled: boolean;
}

export class Table extends React.PureComponent<TableProperties> {
    public static readonly Header = class extends React.PureComponent<TableHeaderProperties> {
        public static readonly displayName = "TableComponent.Header";
        public render(): JSX.Element {
            const { children, className } = this.props;
            return <Table.Context.Consumer>
                {
                    () => <thead className={className}>
                        <tr>
                            {children}
                        </tr>
                    </thead>
                }
            </Table.Context.Consumer>;
        }
    };

    public static readonly Row = class extends React.PureComponent<TableRowProperties> {
        public static readonly displayName = "TableComponent.Row";

        public render(): JSX.Element {
            const { children, className, isActive, onClick } = this.props;
            return <Table.Context.Consumer>
                {
                    () => <tr
                        className={
                            classNames(
                                className,
                                {
                                    active: isActive,
                                    clickable: !!onClick,
                                }
                            )
                        }
                        onClick={this.onClicked}
                    >
                        {children}
                    </tr>
                }
            </Table.Context.Consumer>;
        }

        public readonly onClicked = (e: React.MouseEvent<HTMLTableRowElement>): void => {
            e.stopPropagation();
            if (!this.props.isDisabled && this.props.onClick) {
                this.props.onClick();
            }
        };
    };

    public static readonly Cell = class extends React.PureComponent<TableCellProperties> {
        public static readonly displayName = "TableComponent.Cell";
        public render(): JSX.Element {
            return <td className={this.props.className}>{this.props.children}</td>;
        }
    };

    public static readonly HeaderCell = class extends React.PureComponent<TableHeaderCellProperties> {
        public static readonly displayName = "TableComponent.HeaderCell";
        public render(): JSX.Element {
            return <td className={this.props.className}>
                {
                    this.props.onDirectionChanged && this.props.direction && <Button
                        onClick={this.toggleDirection}
                        caption={this.props.children}
                    ></Button>
                }
                {
                    this.props.onDirectionChanged && !this.props.direction && <Button
                        onClick={this.toggleDirection}
                        caption={this.props.children}
                    ></Button>
                }
                {
                    !this.props.onDirectionChanged && <span>{this.props.children}</span>
                }
            </td>;
        }

        public toggleDirection = (): void => {
            if (!this.props.onDirectionChanged) {
                return;
            }

            if (this.props.direction === "asc") {
                this.props.onDirectionChanged("desc");
                return;
            }

            this.props.onDirectionChanged("asc");
        };
    };

    private static readonly Context = React.createContext<TableContext>(
        {
            isDisabled: false,
        }
    );

    public render(): JSX.Element {
        const { isDisabled = false, children } = this.props;
        const header = ComponentHelper.getChildrenOfType(Table.Header, children);
        const rows = ComponentHelper.getChildrensOfType(Table.Row, children);
        return <Table.Context.Provider
            value={
                {
                    isDisabled: isDisabled || rows.length === 0,
                }
            }
        >
            <div className={
                classNames(
                    "table"
                )
            }>
                <table>
                    {header}
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        </Table.Context.Provider>;
    }
}
