import React from "react";

export class ComponentHelper {
    public static isOfType<P>(type: React.JSXElementConstructor<P>, child: React.ReactNode): boolean {
        const element = (child as React.ReactElement);
        if (element?.type === type) {
            return true;
        }
        return false;
    }

    public static getChildrenOfType<T extends React.JSXElementConstructor<P>, P>(
        type: React.JSXElementConstructor<P>,
        children?: React.ReactNode
    ): React.ReactElement<P, T> | null {
        const childs = this.getChildrensOfType<T, P>(type, children);
        if (childs && childs.length > 0) {
            return childs[0];
        }
        return null;
    }

    public static getChildrensOfType<T extends React.JSXElementConstructor<P>, P>(
        type: React.JSXElementConstructor<P>,
        children?: React.ReactNode
    ): Array<React.ReactElement<P, T>> {
        const results: Array<React.ReactElement<P, T>> = [];
        React.Children.forEach(
            children,
            (child: React.ReactNode) => {
                const element = (child as React.ReactElement<P, T>);

                if (element?.type === type) {
                    results.push(element);
                }
            }
        );

        return results;
    }

    public static getElementChildrens(
        children?: React.ReactNode
    ): Array<React.ReactElement<any, any>> {
        const results: Array<React.ReactElement<any, any>> = [];
        React.Children.forEach(
            children,
            (child: React.ReactNode) => {
                const element = (child as React.ReactElement);

                if (element?.type) {
                    results.push(element);
                }
            }
        );

        return results;
    }

    public static hasOnlyType<P>(
        type: React.JSXElementConstructor<P>,
        children?: React.ReactNode
    ): boolean {
        let hasOther = false;
        React.Children.forEach(
            children,
            (child: React.ReactNode) => {
                const element = (child as React.ReactElement);

                if (element?.type !== type) {
                    hasOther = false;
                }
            }
        );

        return hasOther;
    }

    public static cloneComponent<T extends React.JSXElementConstructor<P>, P>(
        element: React.ReactElement<P, T> | null | undefined,
        defualtProperties: Partial<P>,
        overrideProperties: Partial<P & React.ClassAttributes<any>>,
        ...children: React.ReactNode[]
    ): React.ReactElement<P, T> | null {
        if (!element) {
            return null;
        }

        const clonedProps: P & React.ClassAttributes<T> = {
            ...defualtProperties,
            ...element.props,
            ...overrideProperties,
        };

        return React.cloneElement(element, clonedProps, ...children) as React.ReactElement<P, T>;
    }
}
