export class ObjectPool<T> {
    private pool: T[] = [];
    private creator: () => T;
    private stateManager?: (obj: T, wakeup: boolean) => void;

    public constructor(creator: () => T, stateManager?: (obj: T, wakeup: boolean) => void) {
        this.creator = creator;
        this.stateManager = stateManager;
    }

    public getPool(): T[] {
        return [...this.pool];
    }

    public clearPool(): void {
        this.pool = [];
    }

    public get(count: number): T[] {
        const objects = this.pool.filter((_, i) => i < count);
        const reserve = this.pool.filter((_, i) => i >= count);

        if (this.stateManager) {
            for (const obj of objects) {
                this.stateManager(obj, true);
            }
            for (const obj of reserve) {
                this.stateManager(obj, false);
            }
        }

        while (objects.length < count) {
            const obj = this.creator();
            objects.push(obj);
            this.pool.push(obj);
        }

        return objects;
    }

    public transaction(callback: (getOne: () => T) => void): void {
        const reserve = [...(this.pool)];

        callback(
            () => {
                let obj = reserve.shift();
                if (typeof obj === "undefined") {
                    obj = this.creator();
                    this.pool.push(obj);
                } else if (this.stateManager) {
                    this.stateManager(obj, true);
                }
                return obj;
            }
        );

        if (this.stateManager) {
            for (const obj of reserve) {
                this.stateManager(obj, false);
            }
        }
    }
}
