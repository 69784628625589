import "./crash-provably-fair-modal.style.scss";
import { Button } from "../../../buttons/button.component";
import { CrashApiService } from "../../../../services/crash/crash-api.service";
import { CrashMatchDto } from "../../../../services/crash/dtos/crash-match.dto";
import { CrashMatchSortDirection } from "../../../../services/crash/enums/crash-match-sort-direction.enum";
import { CrashMatchSortMode } from "../../../../services/crash/enums/crash-match-sort-mode.enum";
import { DateHelper } from "@tgg/shared";
import { LowThreshold, MinimumMultiplier } from "../../../../constants/crash.constant";
import { PlanetItem } from "../../../crash-planet/crash-planet.component";
import { ProvablyFairCode } from "./components/provably-fair-code/provably-fair-code.component";
import { ProvablyFairHashString } from "./components/provably-fair-hash-string/provably-fair-hash-string.component";
import { Table } from "../../../table/table.component";
import classNames from "classnames";
import React, { ReactNode } from "react";

export class CrashProvablyFairModal extends React.PureComponent<{
    crashApiService: CrashApiService;
}, {
    lastMatches: CrashMatchDto[];
    total?: number;
    offset?: number;
    status: "fetching" | "error" | "idle";
}> {
    public constructor(props: { crashApiService: CrashApiService }) {
        super(props);
        this.state = {
            lastMatches: [],
            status: "idle",
        };
    }

    public componentDidMount(): void {
        this.getMatches();
    }

    public render(): ReactNode {
        const multiplierDelta = 1 - MinimumMultiplier;
        return <>
            <div className="crash-provably-fair-modal">
                <article>
                    <p>
                        Our Crash is a provably fair game, which means players from all over the world can manually and individually verify
                        the fairness of each match that is hosted by our reliable system.
                    </p>

                    <h2>Technical Details</h2>
                    <p>
                        Crash&apos;s fairness is guaranteed by using a long chain of hashed values that provide a fair distribution of random outcomes.
                        This chain is calculated before any game is initiated and starts with 32 bytes of cryptographically secure pseudorandom numbers,
                        followed by the hash of the previous value.
                        Along with this chain, a 32-byte public salt is also added to ensure that the initial value of the hash chain was not chosen in favor of the house.
                    </p>
                    <p>
                        The result for each match is determined by feeding the following variables into a SHA256 HMAC hashing algorithm:
                    </p>
                    <ol>
                        <li>
                            <b>Public Salt </b>
                            is a hex string of 32 bytes of the chosen value. The public salt of the current chain
                            is <code><ProvablyFairHashString hash={this.state.lastMatches?.find(() => true)?.salt} /></code>
                        </li>
                        <li>
                            <b>Secret </b>
                            is the current hash value of the hash chain.
                        </li>
                    </ol>
                    <ProvablyFairCode>
                        {`
async function hmacSHA256(keyString, messageString) {
	const enc = new TextEncoder("utf-8");
	const key = await window.crypto.subtle.importKey(  
    "raw",
    enc.encode(keyString),
    {
        name: "HMAC",
        hash: {name: "SHA-256"}
    },
    false,
    ["sign", "verify"]
  );
  const sign = await window.crypto.subtle.sign(
        "HMAC",
        key,
        enc.encode(messageString)
  );
  const bytes = new Uint8Array(sign);
  return Array.prototype.map.call(bytes, x => ('00' + x.toString(16)).slice(-2)).join("");
}


async function calculateMultiplier(hashValue, salt, leverage) {
  const randomResult = (await hmacSHA256(hashValue, salt)).toLowerCase();

  if (leverage > 0) {
    const houseLeverage = Math.ceil(100 / leverage);
    const extraCharacters = randomResult.length % 4;
    const startIndex = extraCharacters > 0 ? extraCharacters - 4 : 0;
    let remainder = 0;
    for (let i = startIndex; i < randomResult.length; i += 4) {
      const chunk = parseInt(randomResult.substring(i, i + 4), 16);
      remainder = ((remainder << 16) + chunk) % houseLeverage;
    }

    if (remainder === 0) {
      return ${MinimumMultiplier};
    }
  }

  const randomValue = parseInt(randomResult.substring(0, 52 / 4), 16);
  const maxValue = Math.pow(2, 52);
  return (Math.floor(((maxValue - (randomValue / 50)) / (maxValue - randomValue)) * 100) / 100) - ${multiplierDelta};
}

const secret = "ddcf544330c1fc826cb36e93866e1e3ec292d60e1242ce6ef5468376cc8541ad";
const publicSalt = "56c49d1fe912f52855afc1240febed3ac58b944c2022c1bb64edfdf848c3df4d";
const leverage = 5;
calculateMultiplier(secret, publicSalt, leverage).then(
	(multiplier) => console.log(multiplier)
);
                        `}
                    </ProvablyFairCode>
                    <h2>Recent Rounds</h2>
                    <Table>
                        <Table.Header>
                            <Table.Cell>
                                Game ID
                            </Table.Cell>
                            <Table.Cell>
                                Date
                            </Table.Cell>
                            <Table.Cell>
                                Secret
                            </Table.Cell>
                            {/* <Table.Cell>
                                Duration
                            </Table.Cell> */}
                            <Table.Cell>
                                Multiplier
                            </Table.Cell>
                        </Table.Header>
                        {
                            this.state.lastMatches?.map(
                                (j) => <Table.Row key={j.id.toFixed(0)}>
                                    <Table.Cell>
                                        {j.id}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {DateHelper.formatDateLong(new Date(j.startOfMatch))}
                                    </Table.Cell>
                                    <Table.Cell className={j.endOfMatch ? undefined : "bold"}>
                                        <ProvablyFairHashString hash={j.hash ?? undefined} />
                                    </Table.Cell>
                                    {/* <Table.Cell>
                                        {
                                            !!j.endOfMatch ?
                                                DateHelper.formatTimespan(
                                                    DateHelper.getTimeSpan(new Date(j.endOfMatch), new Date(j.startOfMatch)),
                                                    "{M} minute(s) and {s} second(s)|Less than a second"
                                                ) : "-"
                                        }
                                    </Table.Cell> */}
                                    <Table.Cell
                                        className={
                                            classNames(
                                                {
                                                    low: j.multiplier != null && j.multiplier < LowThreshold,
                                                    high: j.multiplier != null && j.multiplier >= LowThreshold,
                                                }
                                            )
                                        }
                                    >
                                        {
                                            j.multiplier != null ? `${j.multiplier}x` : "-"
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table>
                    {
                        this.hasMore() &&
                        <Button
                            onClick={this.loadMore}
                            isLoading={this.isBusy()}
                            caption="Load more"
                        />
                    }
                </article>
            </div>

            <PlanetItem className="crash-provably-fair-planet" type="purple" before="hash" />
        </>;
    }

    private loadMore = () => {
        this.getMatches();
    };

    private getMatches = () => {
        this.setState({ status: "fetching" });

        this.props.crashApiService
            .getMatches(
                {
                    offset: this.state.offset,
                    direction: CrashMatchSortDirection.Descending,
                    sort: CrashMatchSortMode.Created,
                }
            )
            .then(
                (response) => {
                    this.setState(
                        {
                            lastMatches: [ ...this.state.lastMatches, ...response.result ],
                            offset: response.offset + response.result.length,
                            total: response.total,
                            status: "idle",
                        }
                    );
                }
            )
            .catch(
                () => this.setState(
                    {
                        status: "error",
                    }
                )
            );
    };

    private hasMore(): boolean {
        return (this.state.offset ?? 0) < (this.state.total ?? 0);
    }

    private isBusy(): boolean {
        return this.state.status !== "idle" && this.state.status !== "error";
    }
}
