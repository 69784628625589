import { AudioHelper, ImageHelper } from "@tgg/shared";
import { CrashExplosionAudioAddress } from "../../crash-rendering.constants";
import { CrashRenderingConfig } from "../../crash-rendering-config.interface";
import { CrashRenderingGamePageState } from "../crash-rendering-game.page";
import { CrashRenderingLayer } from "../../crash-rendering-layer";
import Konva from "konva";
import shuttleSpriteSheet from "../../assets/shuttle-sprite-sheet.json";

export class CrashRenderingGameShuttleLayer extends CrashRenderingLayer<{
    shuttle: Konva.Sprite;
}, CrashRenderingGamePageState> {
    private explosionAudio: HTMLAudioElement | undefined;

    public constructor(configuration: CrashRenderingConfig, container: Konva.Group) {
        super(
            configuration,
            container,
            {
                shuttle: new Konva.Sprite(
                    {
                        animation: "fly",
                        animations: shuttleSpriteSheet.animations,
                        frameRate: shuttleSpriteSheet.framerate,
                        frameIndex: 0,
                        offsetX: shuttleSpriteSheet.width - 40,
                        offsetY: shuttleSpriteSheet.height / 2,
                        x: (shuttleSpriteSheet.width / 2) + 3,
                        y: 0,
                        scale: { x: 0.53, y: 0.53 },
                        rotation: -90,
                    } as Konva.SpriteConfig
                ).transformsEnabled("all"),
            }
        );

        ImageHelper.loadImage(shuttleSpriteSheet.image).then(
            (e) => {
                this.shapes.shuttle.image(e);
                this.shapes.shuttle.start();
            },
            () => {
                // ignore
            }
        );
    }

    public config(configuration: CrashRenderingConfig): void {
        if (!configuration.isMuted && this.explosionAudio == null) {
            AudioHelper.loadAudio(CrashExplosionAudioAddress).then(
                (e) => {
                    e.volume = 0.3;
                    e.playbackRate = 1.5;
                    this.explosionAudio = e;
                },
                () => {
                    // ignore
                }
            );
        }

        super.config(configuration);
    }

    public get rotation(): number {
        return this.container.rotation();
    }

    public get absoluteX(): number {
        return this.shapes.shuttle.absolutePosition().x - Math.sin(this.rotation * (Math.PI / 180)) / 2 * this.shapes.shuttle.width();
    }

    public get absoluteY(): number {
        return this.shapes.shuttle.absolutePosition().y + Math.cos(this.rotation * (Math.PI / 180)) / 4 * this.shapes.shuttle.height();
    }

    protected statusChanged(state: CrashRenderingGamePageState): void {
        if (state.status === "finished") {
            if (!this.configuration.isMuted && this.explosionAudio) {
                this.explosionAudio.currentTime = 0;
                // fire and forget
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                void this.explosionAudio.play();
            }

            this.shapes.shuttle.setAttrs(
                {
                    frameIndex: 0,
                    animation: "explosion",
                } as Konva.SpriteConfig
            );

            this.shapes.shuttle.on(
                "frameIndexChange.button",
                () => {
                    if (this.shapes.shuttle.frameIndex() === Math.floor((shuttleSpriteSheet.animations.explosion.length / 4) - 1)) {
                        this.shapes.shuttle.stop();
                        this.shapes.shuttle.off(".button");
                    }
                }
            );
        } else {
            this.shapes.shuttle.setAttrs(
                {
                    frameIndex: 0,
                    animation: "fly",
                } as Konva.SpriteConfig
            );
            this.shapes.shuttle.start();
        }
    }
}
