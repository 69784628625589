import { CrashRenderingConfig } from "../../crash-rendering-config.interface";
import { CrashRenderingGamePageState } from "../crash-rendering-game.page";
import { CrashRenderingGameShuttleLayer } from "./crash-rendering-game-shuttle.layer";
import { CrashRenderingHelper } from "../../crash-rendering.helper";
import { CrashRenderingLayer } from "../../crash-rendering-layer";
import { MinimumMultiplier } from "../../../../../constants/crash.constant";
import Konva from "konva";
import shuttleSpriteSheet from "../../assets/shuttle-sprite-sheet.json";

export class CrashRenderingGameLineLayer extends CrashRenderingLayer<{
    path: Konva.Path;
    shuttle: CrashRenderingGameShuttleLayer;
}, CrashRenderingGamePageState> {
    private static sharedContext = typeof document !== "undefined" ? (document?.createElement("canvas").getContext("2d") ?? undefined) : undefined;
    private currentColor?: string;
    private flash: (color: string) => void;

    public constructor(configuration: CrashRenderingConfig, container: Konva.Group, flash: (color: string) => void) {
        super(
            configuration,
            container,
            {
                path: new Konva.Path(
                    {
                        strokeEnabled: true,
                        fillEnabled: false,
                        strokeWidth: 5,
                        lineCap: "round",
                        data: "",
                    }
                ),
                shuttle: new CrashRenderingGameShuttleLayer(
                    configuration,
                    new Konva.Group(
                        {
                            width: shuttleSpriteSheet.width,
                            height: shuttleSpriteSheet.height,
                            offsetX: (shuttleSpriteSheet.width / 2) + 3,
                            offsetY: 3,
                            rotation: 90,
                        }
                    )
                ),
            }
        );
        this.flash = flash;
    }

    public get shuttle(): CrashRenderingGameShuttleLayer {
        return this.shapes.shuttle;
    }

    private static getScaledCoordinates(elapsedTime: number, height: number, state: CrashRenderingGamePageState) {
        const elapsedPayout = CrashRenderingHelper.getMultiplierForElapsedTime(elapsedTime, state.speed) - MinimumMultiplier;
        return {
            x: (elapsedTime * state.xIncrement),
            y: (height - (elapsedPayout * state.yIncrement)),
        };
    }

    public config(configuration: CrashRenderingConfig): void {
        if (configuration.displayMode === "rich") {
            this.addShape("shuttle");
        } else {
            this.removeShape("shuttle");
        }

        super.config(configuration);
    }

    public tick(state: CrashRenderingGamePageState): void {
        this.tickLine(state);
        super.tick(state);
        this.tickShuttle(state);
        this.shapes.shuttle.tick(state);
    }

    protected statusChanged(state: CrashRenderingGamePageState): void {
        if (state.status === "finished") {
            this.moveShuttleToLine(state);

            // fire and forget
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.finished();
        } else if (state.status === "waiting") {
            this.waiting();
        }
    }

    private waiting() {
        this.shapes.shuttle.setAttrs(
            {
                x: -1 * this.shapes.shuttle.width,
                y: this.height,
                rotation: 90,
            }
        );
    }

    private async finished() {
        await new Promise((resolve) => setTimeout(resolve, 200));

        await this.animate(
            {
                node: this.shapes.shuttle.container,
                rotation: (Math.random() - 0.5) * 360,
                y: this.shapes.shuttle.container.y() + Math.sign(Math.random() - 0.5) * (1 + Math.random() / 2) * 150,
                x: this.shapes.shuttle.container.x() - (0.66 + Math.random() / 2) * 150,
                duration: 5,
                // eslint-disable-next-line @typescript-eslint/unbound-method
                easing: Konva.Easings.EaseIn,
            }
        );

        this.shapes.shuttle.setAttrs(
            {
                x: -1 * this.shapes.shuttle.width,
                y: this.height,
                rotation: 90,
            }
        );
    }

    private tickLine(state: CrashRenderingGamePageState) {
        const height = this.height;
        const width = this.width;
        const endPoint = CrashRenderingGameLineLayer.getScaledCoordinates(state.elapsed, height, state);
        const midPoint = CrashRenderingGameLineLayer.getScaledCoordinates(state.elapsed / 2, height, state);

        const lineColor = CrashRenderingHelper.getMultiplierColor(state.multiplier);
        if (state.status === "idle" || state.status === "waiting") {
            this.shapes.path.visible(false);
        } else {
            const gradient = CrashRenderingGameLineLayer.sharedContext?.createLinearGradient(0, 0, endPoint.x, endPoint.y);
            gradient?.addColorStop(0, "transparent");
            gradient?.addColorStop(1.00, lineColor);
            this.shapes.path.setAttrs(
                {
                    visible: true,
                    data: `M ${0} ${height} Q ${midPoint.x} ${midPoint.y}, ${endPoint.x} ${endPoint.y}`,
                    stroke: gradient,
                    shadowColor: lineColor,
                    strokeWidth: Math.min((endPoint.x / width) * 8, 5),
                } as Konva.PathConfig
            );
        }

        if (state.status !== "started") {
            this.currentColor = undefined;
        } else if (lineColor !== this.currentColor) {
            if (this.currentColor && this.configuration.displayMode === "rich") {
                this.flash(lineColor);
            }
            this.currentColor = lineColor;
        }
    }

    private tickShuttle(state: CrashRenderingGamePageState) {
        if (state.status === "waiting") {
            this.flyShuttleIn(state);
        } else if (state.status !== "finished" && state.status !== "idle") {
            this.moveShuttleToLine(state);
        }
    }

    private flyShuttleIn(state: CrashRenderingGamePageState) {
        if (state.countdown == null || state.countdown > 100) {
            return;
        }

        const height = this.height;
        this.shapes.shuttle.setAttrs(
            {
                x: ((state.countdown / -100) - 1) * this.shapes.shuttle.width,
                y: height,
                rotation: 90,
            }
        );
    }

    private moveShuttleToLine(state: CrashRenderingGamePageState) {
        const height = this.height;
        const endPoint = CrashRenderingGameLineLayer.getScaledCoordinates(state.elapsed, height, state);
        const midPoint = CrashRenderingGameLineLayer.getScaledCoordinates(state.elapsed / 2, height, state);

        const deltaX = endPoint.x - midPoint.x;
        const deltaY = endPoint.y - midPoint.y;
        const radian = deltaX > Number.EPSILON ? Math.atan(deltaY / deltaX) : 0;
        const angle = radian * (180 / Math.PI);

        this.shapes.shuttle.setAttrs(
            {
                x: endPoint.x,
                y: endPoint.y,
                rotation: angle + 90,
            }
        );
    }
}
