import { CrashRenderingConfig } from "../crash-rendering-config.interface";
import { CrashRenderingLayer } from "../crash-rendering-layer";
import { ImageHelper } from "@tgg/shared";
import Konva from "konva";
import shuttleSpriteSheet from "../assets/shuttle-sprite-sheet.json";

export class CrashRenderingIdlePage extends CrashRenderingLayer<{
    title: Konva.Text;
    shuttle: Konva.Sprite;
}> {
    public constructor(configuration: CrashRenderingConfig, container: Konva.Group) {
        super(
            configuration,
            container,
            {
                title: new Konva.Text(
                    {
                        text: "Preparing launchpad ...",
                        fillEnabled: true,
                        align: "center",
                        verticalAlign: "middle",
                        fontFamily: "Montserrat",
                        fontSize: 12,
                        x: 0,
                        fill: "#909090",
                    }
                ).transformsEnabled("position"),
                shuttle: new Konva.Sprite(
                    {
                        animation: "fly",
                        animations: shuttleSpriteSheet.animations,
                        frameRate: shuttleSpriteSheet.framerate,
                        frameIndex: 0,
                        offsetX: shuttleSpriteSheet.width / 2,
                        offsetY: shuttleSpriteSheet.height / 2,
                        scale: { x: 0.75, y: 0.75 },
                    }
                ).transformsEnabled("all"),
            }
        );

        ImageHelper.loadImage(shuttleSpriteSheet.image).then(
            (e) => {
                this.shapes.shuttle.image(e);
                this.shapes.shuttle.start();
            },
            () => {
                // ignore
            }
        );

        this.config(configuration);
        this.resize();
    }

    public resize(): void {
        const width = this.width;
        const height = this.height;

        this.shapes.shuttle.setAttrs(
            {
                x: width / 2,
                y: (height / 2) - 40,
            } as Konva.SpriteConfig
        );

        this.shapes.title.setAttrs(
            {
                width,
                y: (height / 2) + 25,
            } as Konva.TextConfig
        );

        super.resize();
    }
}
